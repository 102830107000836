.manage--roles {
  color: white;

  input {
    text-transform: capitalize;
  }

  .success-container {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
    
    .success-msg {
      background-color: rgb(71, 202, 93);
      font: normal normal normal 14px/18px Ubuntu;
      letter-spacing: 0px;
      color: #ffffff;
      padding: .7rem 4rem;
      border-radius: 5px;
    }

    .error-msg--role {
      background-color: rgb(245, 49, 49);
    }
  }



  %button { 
      background: transparent
        linear-gradient(128deg, #d0021b 0%, #68010e 100%) 0% 0% no-repeat
        padding-box;
      border-radius: 5px;
      font: normal normal bold 16px/18px Ubuntu;
      letter-spacing: 0px;
      color: #ffffff;
      width: 200px;
      height: 44px;
      margin: 0 2rem;
  }

  .headerdiv {
    background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
    padding:15px;
    border-radius: 5px;
    color: white;

p{
    font-size: 18px;
    font-weight: bold;
}
}
  label {
    margin-left: 0px;
    margin-bottom: 0;
  }
  input, select {
    min-width: 100%;
    height: 44px;
    margin-left: 10px;
    border-radius: 5px;
    margin: 4px 0;
    border: 1px solid black;
  }
  .save {
    background: transparent linear-gradient(137deg, #d0021b 0%, #68010e 100%) 0%
      0% no-repeat padding-box;
    color: white;
    position: relative;
    width: 100px;
    height: 44px;
    border-radius: 7px;
    margin-top: 40px;
    left: 45%;
    margin-bottom: 20px;
  }

  .button {
    color: white;
    padding: 0 1rem;
    // background-color:#333669;
    border-radius: 5px;
    
    &--2 {
      background-color:#31920e;
    }
  }

  .section--2 {
    padding: 1rem 0 0 0;
    h1 {
      font: normal normal bold 30px/28px Ubuntu;
      color: #262c2c;
      padding: 1rem 0;
    }
    .sub--section {
      position: relative;
    }
    .main-filter {
      margin: 1rem 0 2rem auto;

      input {
        border: 1px solid #b52268;
        border-radius: 5px;
        max-width: 230px;
        height: 44px;
        padding: 0 0 0 1rem;
        margin-left: -243px;
      }
      button {
        background: transparent
          linear-gradient(128deg, #d0021b 0%, #68010e 100%) 0% 0% no-repeat
          padding-box;
        border-radius: 5px;
        font: normal normal bold 16px/18px Ubuntu;
        letter-spacing: 0px;
        color: #ffffff;
        width: 200px;
        height: 44px;
        margin: 0 2rem;
        @extend %button;

        &:nth-child(3) {
          background: transparent;
          font: normal normal bold 16px/18px Ubuntu;
          color: #d0021b;
          border: 2px solid #d0021b;
          width: 200px;
          margin-right: 0;
        }
      }
    }
    table {
        width: 100%;
        font: 14px;
        white-space: nowrap;
        margin-bottom: 40px;
    }

    td, th {
        text-align: center;
        padding: 8px;
        border: none;
    }

    // tr:nth-child(even) {
    //     background-color: #8f8c8c;
    // }

    .table--icon {
        margin-left: 1rem;

        &--2 {
            margin-left: .5rem;
        }
    }

    
  }
  .heading-description {
    p { 
      margin: 0;
      padding: 1rem 0 0rem;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .select--permission {

    button {
      // width: 112px;
      padding: 0 1rem;
      background: transparent linear-gradient(138deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 6px 16px #B522684D;
      border-radius: 5px;
      font: normal normal bold 16px/18px Ubuntu;
      letter-spacing: 0px;
      color: #FFFFFF;
      height: 44px;
      margin-top: 1rem;
    }
  }

  .show-permission--modal {
    position: absolute;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    z-index: 999;
    justify-content: center;
    background-color: rgba(141, 141, 141, 0.213);
    color: rgba(255, 255, 255, 1);
  }

  .permission--container {
    background-color: white;
    color: black;
    padding: 2rem;
    border-radius: 10px;
    height: 450px;
    width: 100%;
    margin: 0 8rem;
    overflow: hidden;
  }

  .close--permission {
    position: absolute;
    right: 1rem;
    top: -2rem;
    transform: scale(1.5);
    cursor: pointer;
  }

  .table--1 {
    width: 100%;
    min-width: 400px;
    height: 400px;
    overflow-y: scroll;
    padding-bottom: 6rem;
  }
  .table--2 {
    width: 100%;
    max-width: 1200px;
    min-width: 400px;
    height: 400px;
  }

  .modal-container {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(128, 128, 133, 0.5);
    transition: all 3s ease-in-out;
    z-index: 10;

    .modal--main {
      background-color: white;
      border-bottom: 1px solid #dcdacb;
      box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);
      // padding: 4rem;
      border-radius: 5px;
      position: relative;
      // height: 300px;
      width: 50%;
      min-width: 300px;
      // align-items: center;
      padding: 2rem 2rem;

      .buttons {
          margin-top: 1rem; 

          button {
            @extend %button;
            width: 100%;
            font-size: 16px;
            white-space: nowrap;
            padding: 0 1rem;
            margin: 1rem;
            height: 44px;
          }
      }
      h5 {
        padding: 0;
        margin: 0;
      }
      p {
        font: normal normal medium 20px/24px Ubuntu;
        font-size: 16px;
        padding: .3rem 0 1rem 0;
      }
    }
    
    span {
      position: absolute;
      top: 0.7rem;
      right: 1.5rem;
      font-size: 1.2rem;
      cursor: pointer;
    }
  }
  
  .role--permission {
    background-color: #f2d8dc;
    border: 1px solid #b8b8b8;
    outline: none;
    font-size: 14px;
    border-radius: 4px;
    padding: 7px 10px;
  }
  .main-filter {
    margin: 1rem 12rem 0 auto;

    .filter  {
        border-radius: 5px;
        width: 220px;
        min-width: 150px;
        height: 44px;
        padding:  0 0 0 1rem;
    }
  }

  section {
    margin-top: 10px;
  }


  .input--list {
    position: relative;
    padding: 10px 0;
    border-bottom: 1px solid whitesmoke;
    
  .input-container--1{
    grid-gap: 1rem;
    padding-bottom: 1rem;
    flex-wrap: wrap;

    .flex--3 {
        flex: 1 1;
        min-width: 150px;
        max-width: 600px;
    }
    .emailinput{
        flex: 1 1;
        min-width: 150px;
        max-width: 570px; 
    }
     }

     .button{
      margin-top: 20px;
  
    }
    
    
    
  }
  .updatebutton{
     background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
      border-radius: 5px;
      font: normal normal bold 16px/18px Ubuntu;
      letter-spacing: 0px;
      color: #FFFFFF;
      width: 160px;
      height: 44px;
      margin: 0 2rem;
  }
  
}
