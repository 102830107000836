.TmeEodProcesses{
    .heading{
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
        padding:15px;
        border-radius: 5px;
        color: white;
    
        p{
            font-size: 18px;
            font-weight: bold;
            
        }
    }

    select{
        height:44px;
        margin-left: 10px;
        border-radius: 5px;
        margin:1px 0;
        padding-left: 1rem;
        position: relative;
    }


    .textarea{
      width: 100%;
      min-width: 600px;
        // min-width: 200px;
      max-width: 700px;
        // height:300px;
        background-color:rgb(241, 229, 229);
        // border:1px solid black;
        padding: 30px;

        ul{
          width: 100%;
        }

        .clearMessage{
          // border: 1px solid red;
          width: 100%;
          position: relative;
          color: #000;
          // left: 2rem;
          display: flex;
          justify-content: flex-end;
          // align-items: center;
          margin-bottom: 2rem;
          cursor: pointer;
          }

          .eClear{
            margin-left: 1rem;
            // width: 100%;
          }

          .eClear:hover{
            background-color: #eb9aa3;
          }

    }

    label {
        font: normal normal medium 18px/21px Ubuntu;
        letter-spacing: 0px;
        color: #262C2C;
        padding-bottom: 5px;
        margin-bottom: 0; 
    }



    input {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 5px;
        min-width: 300px;
        height: 44px;
    }

    .button {
        width: 100%;
        text-align: center;
        margin-top: 3rem;

        button {
            background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
            box-shadow: 0px 6px 16px #B522684D;
            border-radius: 5px;
            font: normal normal bold 16px/18px Ubuntu;
            letter-spacing: 0px;
            color: #FFFFFF;
            width: 160px;
            height: 44px;
        }
    }

    .horizontalLine{
      color: #000;
    }

    .section--1{
        .eod{

          

            grid-gap: 1rem;
            flex-wrap: wrap;
            margin-bottom: 20px;
            .flex--3 {
                flex: 1 1 150px;
              }
              
              .input--1 {
                max-width: 150px;
              }
            
              .input--2 {
                min-width: 200px;
                max-width: 500px;
                
              }
              .input--3{
                max-width: 300px;
              }
              .input--4{
                max-width: 200px;
              }
              .input--5{
                max-width: 135px;
              }
              input {
                min-width: 10px;
              }
        }

        .button-last{
            width: 100%;
            margin-right: auto;
            // margin: 0 auto;
            display: flex;
            justify-content: flex-end;
           
    
            button{
                background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 6px 16px #B522684D;
                border-radius: 5px;
                font: normal normal bold 16px/18px Ubuntu;
                letter-spacing: 0px;
                color: #FFFFFF;
                width: 180px;
                height: 46px;
                margin-top: 1rem;
                
            }
        }
    }
}