.investment--product {
  color: #262C2C;
  margin-bottom: 2rem;


  .headerdiv {
    background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
    padding: 15px;
    border-radius: 5px;
    color: white;

    p {
      font-size: 18px;
      font-weight: bold;
    }
  }

  label {
    margin-left: 0px;
    margin-bottom: 0;
    cursor: pointer;
  }

  input {
    min-width: 100%;
    height: 44px;
    margin-left: 10px;
    border-radius: 5px;
    margin: 4px 0;
  }

  select {
    min-width: 100%;
    height: 44px;
    margin-left: 10px;
    border-radius: 5px;
    margin: 4px 0;
    padding-left: 5px;
  }

  .checboxdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 170px;

    .productcheckbox {
      min-width: 0;
      height: 15px;
      margin-left: 0;
      border-radius: 0;
      margin: 0;
    }

    .span {
      position: relative;
      top: 5px;

    }
  }

  .button--cont {
    display: flex;
    justify-content: flex-end;
  }

  .next {
    background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
    color: white;
    position: relative;
    width: 100px;
    height: 44px;
    border-radius: 7px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .next-page--1 {
    padding: 2rem;

    input {
      min-width: 10px;
    }

    .section--1 {
      grid-gap: 1rem;
      align-items: center;
      flex-wrap: wrap;


      label {
        margin: 0;
      }

      .flex--2 {
        white-space: nowrap;

        input {
          margin-right: 10px;
        }
      }
    }

    .section--2 {
      grid-gap: 1rem;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      align-items: center;
      margin: 1rem 0 3rem;

      label,
      select,
      input {
        margin: 0;
      }

      .flex--2 {
        // white-space: nowrap;

        input {
          margin-right: 10px;
        }
      }

    }

    #button--cont {
      width: 100%;
      justify-content: flex-end;
      padding: 0 0 2rem;
    }
  }

  .color {
    color: red;
  }
}