.bulk--posting {

   
    label {
        margin: 0;
    }

    .headerdiv {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        padding: 15px;
        border-radius: 5px;
        color: white;

        p {
            font-weight: bold;
            font-size: 18px
        }
    }

    .paper {
        margin-top: 20px;
        padding: 25px;
        height:0px;

        .addbtn--container {
            display: flex;
            grid-gap: 4rem;
            flex-wrap: wrap;
            max-width: 50rem;
            margin: 0 auto;


            .flex--input1 {
                flex: 1 1 150px;
                max-width: 120px;
                min-width: 100px;
            }

            input {
                height: 44px;
                border-radius: 5px;
            }
        }

        .flex--btn {
            width: 50px;
            align-self: flex-end;

            .addbtn {
                border-radius: 60px;
                width: 45px;
                height: 30px;
                background-color: white;
                color: #d0021b;
                box-shadow: 2px 2px 2px 2px #ebacb3;
                margin-bottom: 8px;
            }

        }

        .section1 {
            margin-top: 50px;
            display: flex;
            grid-gap: 1rem;
            flex-wrap: wrap;
            
            .flex--3 {
                flex: 1 1 200px;
                max-width: 140px;
            }
            
            .flex--ind {
                max-width: 40px;
                min-width: 40px;
                
                input {
                    height: 44px;
                    border-radius: 3px;
                }
            }
            
            .flex--ind2 {
                min-width: 160px;
                
                input {
                    height: 44px;
                    border-radius: 3px;
                    max-width: 200px;
                }
            }
            
            .select--input {
                flex: 1 1;
                max-width: 90px;
                min-width: 80px;
                // border: 2px solid red;


            }

            select {
                height: 44px;
                border-radius: 3px;

            }

            input {
                height: 44px;
                border-radius: 3px;
                max-width: 140px;
            }
           .down-div-flex{
               display: flex;
               margin-top: 30px;
               .down-div-flex-1{
                   margin-left: 45px
               }
           }
        }

        .btn {
            background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
            color: white;
            position: relative;
            width: 180px;
            height: 44px;
            border-radius: 7px;
            margin-top: 80px;
            left: 70%;
            margin-bottom: 25px;
        }
    }
    
}