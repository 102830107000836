.loan--facilitylimit {
    .headerdiv {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        padding: 15px;
        border-radius: 5px;
        color: white;

        p {
            font-size: 18px;
            font-weight: bold;
        }
    }

    select{
        width: 100%;
        height:44px;
        margin-left: 10px;
        border-radius: 5px;
        margin: 4px 0px;
    }
    label{
        margin-left: 0px;
        margin-bottom: 0px;
    }
    input{
     width: 100%;
     height:44px;
     margin-left: 10px;
     border-radius: 5px; 
     margin: 4px 0px; 
    }

    .one__button{
        // border: 1px solid red;
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
    
    .save__button{
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;  
        color:white;
        position:relative;
        width:180px;
        height:44px;
        border-radius: 7px;
        margin-top: 1rem;
    }
}