.createable--periods{
   

    input {
        outline: none;
        border: 1px solid black
    }

    .headerdiv{
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
        padding:15px;
        border-radius: 5px;
        color: white;
    
    p{
       font-size: 18px;
       font-weight: bold;
    }
    } 
    select{
        min-width: 95%;
        height:44px;
        margin-left: 10px;
        border-radius: 5px;
        margin: 0;
        padding-left: 1rem;
    }
    label{
        margin-left: 0px;
        margin-bottom: 4px;
    }
    input{
     min-width: 95%;
     height:44px;
     margin-left: 10px;
     border-radius: 5px; 
     margin:0px 0, 
    }
    .savebtn{
        width:20%;
          margin:50px auto;
          .save{
              width:70%;
              height:44px;
              background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
              color:white;
              border-radius: 5px;
          }
        }
    .submit{
        color: #FFFFFF;
        margin:9px;
        height: 44px;
        width:88px;
        border-radius: 5px;
        margin-left: 17px;
        background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
    }
    .remove{
        background: transparent;
        // font: normal normal bold 16px/18px Ubuntu;
        color: #D0021B;
        border: 2px solid #D0021B;
        margin:0px;
        width: 135px;
        height:44px;
        border-radius: 5px;
    }

    .filter {
        justify-content: flex-end;
        margin: 0 0 10px 0;
        width: 100%;
        grid-gap: 0 1rem;

        input {
            margin: 0;
        }
    }
        

    .updater--box {
        #button--cont {
            margin: 2rem 0 0;
        }

        p {
            margin: 0;
        }
    }

    

    .date-styling {
        padding: 1rem;
        width: 300px;
        z-index: 10;
        position: relative;
    }

    .main-filter-z {
        margin: 0rem 0 0rem auto;
    
        input {
            margin: 0rem 0 0rem auto;
            border-radius: 5px;
            width: 30%;
            min-width: 220px;
            height: 44px;
            padding: 0 0 0 5px;
        }
    }
}
