.fundTransfer {
  padding-bottom: 5rem;
  border-bottom: 1px solid #dcdacb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 4px 12px rgba(0, 0, 0, .08);

  .section--1 {
    padding-bottom: 5rem; 

    %label-input {
        label {
            letter-spacing: 0px;
            color: #262C2C;
            margin-bottom: 3px;
        }

        input {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #707070;
            border-radius: 5px;
            width: 100%;
            max-width: 500px;
            // min-width: 250px;
            height: 44px;
            color: rgb(65, 64, 64);
            padding-left: 1rem;

        }
    }



    .button-last {
        width: 90%;
        margin: 0 auto;
        display: flex;

        button {
            background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
            box-shadow: 0px 6px 16px #B522684D;
            border-radius: 5px;
            font: normal normal bold 16px/18px Ubuntu;
            letter-spacing: 0px;
            color: #FFFFFF;
            width: 180px;
            height: 44px;
            margin-top: 2rem;
            margin-left: auto;
        }
    }

    .sub-section {
        @extend %label-input;
        
        .container-main {
            grid-gap: 0.5rem;
        }

        .input-container {
            width: 50%;
            grid-gap: 0.5rem;
            padding-bottom: 2rem;

            .input-container-new{
              display: flex;
              // align-items: flex-end;
              // justify-content: space-between;
            }

            .fundTransferDetails{
              display: flex;
              align-items: center;
              background: #FFEAED;
              padding: 1rem;
              color: #D0021B;
              border-radius: 5px;
              width: 100%;
              margin-bottom: 1.2rem;
              margin-left: 1rem;
            }

            &--1 {
                grid-gap: 0.5rem;

                .sub-input {
                    align-self: flex-end;
                }
            }

        }
    }

    .last-input {
        padding: 1rem 0;

        input {
            max-width: 100%;
        }
    }
}

  .product--def {
    grid-gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 7px;

    .flex--3 {
      flex: 1 1 150px;
    }

    .input--1 {
      max-width: 150px;
    }

    .input--2 {
      min-width: 200px;
      max-width: 500px
    }

    .input--3 {
      max-width: 600px;
    }

    .input--4 {
      max-width: 700px;
    }

    .input--5 {
      max-width: 205px;
    }

    .input--6 {
      max-width: 400px
    }
    .input--7 {
      min-width: 90%;
      max-width: 100%
    }

    input {
      min-width: 10px;
    }

    label {
      letter-spacing: 0px;
      color: #262C2C;
      margin-bottom: 4px;
    }

    input {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      border-radius: 5px;
      height: 44px;
      color: rgb(65, 64, 64);
      padding-left: 1rem;
      // margin: 4px 0;

    }

    .sub-input {
      align-self: flex-end;
    }

  }

  button {
    background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 16px #B522684D;
    border-radius: 5px;
    letter-spacing: 0px;
    color: #FFFFFF;
    width: 180px;
    height: 44px;
    margin-top: 2rem;
    margin-bottom: 25px;
  }

  .main {
    padding: 25px;
  }

  .sub--section {
    position: relative;
    right: 0;
  }

  .main-filter {
    margin: 1rem 0 2rem auto;

    .filter {
      border: 1px solid #B52268;
      border-radius: 5px;
      width: 30px;
      min-width: 150px;
      height: 44px;
      padding: 0 0 0 1rem;
    }

    button {
      background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
      border-radius: 5px;
      font: normal normal bold 16px/18px Ubuntu;
      letter-spacing: 0px;
      color: #FFFFFF;
      width: 160px;
      height: 44px;
      margin: 0 2rem;

      &:nth-child(3) {
        background: transparent;
        font: normal normal bold 16px/18px Ubuntu;
        color: #D0021B;
        border: 2px solid #D0021B;
        width: 200px;
        margin-right: 0;
      }

    }
  }

  %label-input {
    label {
      letter-spacing: 0px;
      color: #262C2C;
    }

    input {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      border-radius: 5px;
      width: 100%;
      max-width: 500px;
      // min-width: 250px;
      height: 44px;
      color: rgb(65, 64, 64);
      padding-left: 1rem;

    }
  }



  .button-last {
    width: 90%;
    margin: 0 auto;
    display: flex;

    button {
      background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 6px 16px #B522684D;
      border-radius: 5px;
      font: normal normal bold 16px/18px Ubuntu;
      letter-spacing: 0px;
      color: #FFFFFF;
      width: 180px;
      height: 44px;
      margin-top: 2rem;
      margin-left: auto;

    }
  }

  .last-input {
    padding: 1rem 0;

    input {
      max-width: 100%;
    }
  }
}