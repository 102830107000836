.verify--user{
    img {
        width: 170px;

        &:nth-child(1) {
            padding: 3rem 0 0;
        }

        &:nth-child(3) {
            padding: 3rem 0 0rem;
        }

    }
    
    .section--1{
        margin-top: 8rem;
        .open--mail{
            // border: 1px solid green;
            width: 10%;
            margin: 0 auto;
            // padding-top: 4rem;
            // margin-left: 7rem;
        }
        .verify--section-1{
            display: flex;
            // border: 1px solid red;
            // align-content: center;
            // justify-content: center;
            // margin: 0 auto;
            // margin: 0 auto;
            .content{
                margin: 0 auto;
            }

            button{
                background: #D0021B 0% 0% no-repeat padding-box;
                box-shadow: 0px 5px 5px #aaa3a65c;
                border-radius: 5px;
                max-width: 430px;
                min-width: 300px;
                width: 100%;
                height: 48px;
                font: normal normal bold 16px/18px Ubuntu;
                color: #FFFFFF;
                transition: all .2s ease-in;
                margin-top: 2rem;

                &:hover {
                    box-shadow: 1px 5px 10px #aaa3a65c;
                }
            }
        }

        img {
            width: 170px;

            &:nth-child(1) {
                padding: 3rem 0 0;
            }

            &:nth-child(3) {
                padding: 3rem 0 0rem;
            }

        }
    }
}