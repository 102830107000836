.teller--product {
  color: #262C2C;

  input {
    max-width: 150px;
  }


  .headerdiv {
    background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
    padding: 15px;
    border-radius: 5px;
    color: white;

    p {
      font-size: 18px;
      font-weight: bold;
    }
  }

  label {
    margin-left: 0px;
    margin-bottom: 0;
  }

  input {
    min-width: 100%;
    height: 44px;
    margin-left: 10px;
    border-radius: 5px;
    margin: 4px 0;
  }

  select {
    min-width: 100%;
    height: 44px;
    margin-left: 10px;
    border-radius: 5px;
    margin: 4px 0;
  }

  .section--main {
    grid-gap: 1rem;
    flex-wrap: wrap;
    padding: 1rem 8px;

    label {
      min-width: 10px;
    }
  }

  .checboxdiv {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    min-width: 10px;

    // max-width: 100px;
    .productcheckbox {
      min-width: 10px;
      height: 15px;
      margin-left: -5;
      border-radius: 0;
      margin: 0;
    }
  }

  .button-p {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    .button {
      border-radius: 50%;
      padding: 0;
      width: 50px;
      font-size: 1.5rem;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 5px #C2000A57;
      color: #C2000A;
    }



    p {
      font: normal normal 300 16px/21px Ubuntu;
      letter-spacing: 0px;
      color: #C2000A;
      padding: 1rem 0;
    }

    .button-sub {
      position: relative;
      top: -25px
    }
  }

  h4 {
    margin-top: 50px;
  }

  .charges {
    width: 100%;
  }

  .save {
    background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
    color: white;
    position: relative;
    width: 100px;
    height: 44px;
    border-radius: 7px;
    margin-top: 20px;
    left: 90%;
  }



  .table--section {
    padding-top: 0rem;
    margin: 0 2rem;

  }

  .grid--cont--z {
    margin: 0 0 1rem 0;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));

    label {
      white-space: nowrap;
      margin: 0;
      padding: 0;
    }

    input,
    select {
      max-width: 350px;
      min-width: 100px;
      margin: 5px 0 0;
    }


    // input, select {

    // }

    button {
      background: #FFEAED 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 5px;
      font: normal normal normal 16px/18px Ubuntu;
      letter-spacing: 0px;
      color: #2A2A2A;
      width: 112px;
      height: 44px;
      align-self: flex-end;
    }
  }

  .sub--section-z {
    input {
      min-width: 200px;
    }
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    grid-gap: 30px;

    &:hover {
      color: #2E2E2E;
      cursor: pointer;
    }

    p{
      cursor: pointer;
      color: red;
    }

    .icon {
      color: #2E2E2E
    }
  }


}