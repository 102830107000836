.navbar {
    background-color: #ffffff;
    padding: 0rem 1rem .8rem;
    justify-content: space-between;
    box-shadow: 0 2px 5px rgb(226, 222, 222);
    position: relative;
    z-index: 5;
    position: fixed;
    top: 0;
    width: 100%;

    .hamburger {
        // height: 0;
        font-size: 1.6rem;
        cursor: pointer;
        margin: 1rem 1rem 0;
        display: none;
    }

    img {
        width: 120px;
        margin-top: -.2rem;
    }

    &--description {
        flex: 0 0 80%;
        justify-content: space-between;
        margin-top: 1rem;
        
        p:first-child {
            font: normal normal 400 20px/0px Ubuntu;
            letter-spacing: 0px;
            color: #2A2A2A;
            padding-right: 1rem;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: 2px;
                height: 22px;
                background-color: rgb(89, 89, 89);
                top: -.7rem;
                right: 7px;
            }
        }

        p:nth-child(2) {
            font: normal normal medium 18px/0px Ubuntu;
        }

        button {
            width: 111px;
            height: 40px;
            background-color: #4C4C4C;
            font: normal normal normal 18px/21px Ubuntu;
            letter-spacing: 0px;
            color: #FFFFFF;
            border-radius: 5px;
        }
    }
} 

@media screen and (max-width: 900px) {
    .navbar {
        &--description {
            flex: 1 0 30%;

            p {
                margin: 0 1rem 0 auto;
                font-size: 15px;
            }

            button {
                width: 90px;
                height: 37px;
                background-color: #4C4C4C;
                font: normal normal normal 16px/21px Ubuntu;
                letter-spacing: 0px;
                color: #FFFFFF;
                border-radius: 5px;
            }
        }

        .TME {
            display: none;
        }

        .hamburger {
            display: inline-block;
        }
    }
}