.account--instructions{

    %label-input {
        label {
            font: normal normal 500 18px/21px Ubuntu;
            letter-spacing: 0px;
            color: #262C2C;
        }

        input {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #707070;
            border-radius: 5px;
            width: 100%;
            // max-width: 400px;
            // min-width: 250px;
            height: 46px;
            color: rgb(65, 64, 64);
            padding-left: 1rem;

        }
    }
    .sub--main{
        .label--input{
            @extend%label-input;
            
            select{
              min-width:100%;
              height:44px;
              margin-left: 10px;
              border-radius: 5px;
              margin:10px 0;
              
          }
        }
    }
    // input {
    //     // @extend%label-input;
    //     // width: 100%;
    //     // height: 44px;
    //     // margin-left: 10px;
    //     // border-radius: 5px;
    //     // margin: 10px 0;
        
    //   }
    // label{
    //     // @extend%label-input;
    // }
}