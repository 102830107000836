.reports--container {
    background-color: whitesmoke;
    padding-bottom: 2rem;
    .heading {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        padding: 15px 1.5rem;
        font-size: 18px;
        border-radius: 5px;
        font: normal normal bold 18px/28px Ubuntu;
        letter-spacing: 0px;
        color: #FFFFFF;
        // margin-bottom: 3rem;
    }

    .section--1 {
        padding: 3rem 3rem;

        .box {
            flex: 1 0 250px;
            // max-width: 270px;
            min-width: 300px;
            height: 197px;
            background: transparent linear-gradient(180deg, #EB68CE 0%, #5c0549 100%) 0% 0% no-repeat padding-box;
            color: white;
            border-radius: 5px;
            padding: 3rem;

            &--2 {
                background: transparent linear-gradient(180deg, #F744A4 0%, #7D0545 100%) 0% 0% no-repeat padding-box;
            }
            &--3 {
                background: transparent linear-gradient(180deg, #9A7A89 0%, #6B4E5C 100%) 0% 0% no-repeat padding-box;
            }
            &--4 {
                background: transparent linear-gradient(180deg, #21E261 0%, #410233 100%) 0% 0% no-repeat padding-box;
            }
            &--5 {
               background: #653CD6 0% 0% no-repeat padding-box;
            }

            &--6 {
                background: transparent linear-gradient(189deg, #27E2C9 0%, #410233 100%) 0% 0% no-repeat padding-box;
            }
            
            p {
                font: normal normal medium 18px/21px Ubuntu;
            }

            h1 {
                font: normal normal bold 36px/42px Ubuntu;
                padding-top: 2rem;
            }
        }
    }

    .section--2 {
        padding: 0 3rem; 

        .line-chart--1 {
            flex: 1 1 450px;
            max-width: 50%;
            background-color: white;
            padding: 8rem 1rem 0;
        }
        .last--section {
            grid-gap: 1rem;
            flex: 1 1;
            
            .chart {
                background-color: white;
                max-width: 500px;
                padding: 10px;
            }
        }
    }

    .section--3 {
        padding: 3rem;
        .box {
            background-color: white;
            flex: 1 1;
            padding: 2rem;
            position: relative;
            overflow: hidden;
            
            .span {
                background-color: #C2000A;
                color: #C2000A;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100px;
                height: 50px;
                transform: rotate(45deg) translate(-10px, 55px);
            }

            &--1 {
                text-align: center;
                
                button {
                    background-color: white;
                    padding: .5rem;
                    border-radius: 4px;
                    margin-top: 10px;
                }
            }

            &--2 {
                
                .total {
                    // padding: 1rem;
                    grid-gap: 2rem;
                    height: 100%;
                    justify-content: center;
                }

                span {
                    font-weight: bold;
                }
                
                p:first-child {
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 3px;
                        height: 100%;
                        right: -15px;
                        top: 0;
                        background-color: black;
                    }
                }
            }
        }
    }
}


