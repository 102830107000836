.dash {
    background-color: whitesmoke;
    .heading {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        padding: 15px 1.5rem;
        font-size: 18px;
        border-radius: 5px;
        font: normal normal bold 18px/28px Ubuntu;
        letter-spacing: 0px;
        color: #FFFFFF;
        // margin-bottom: 3rem;
    } 
    
    .section {
        padding: 15px;
        grid-gap: 1rem;
        flex-wrap: wrap;
    }
    
    .line--chart {
        flex: 1 1 300px;
        min-width: 300px;
        background-color: rgb(255, 255, 255);
        padding: 10px;
        
        h1 {
            font: normal normal medium 16px/18px Ubuntu;
            font-size: 18px;
            letter-spacing: 0px;
            color: #262C2C;
        }
    }
    
    .section--2 {
        padding: 15px;
        flex-wrap: wrap;
        grid-gap: 1rem;

        .sub {
            background-color: rgb(255, 255, 255);
            // height: auto;
            // width: 500px;
            
            flex: 1 1 350px;
            
            &-section--1 {
                display: flex;
                justify-content: space-between;
                padding: 20px 25px 0;
                h6 {
                    font: normal normal medium 16px/18px Ubuntu;
                    letter-spacing: 0px;
                    color: #262C2C;
                }
            }
            
            &-section--2 {
                align-self: center;
                margin: auto 0;
                align-items: center;
                padding: 4rem 0 6rem;

                .openBatchDetails{
                   cursor: pointer; 
                }

                .openBatchDetails:hover{
                    color: #D0021B;
                 }

                .icon {
                    padding: 5px;
                    border-radius: 50%;
                    color: white;
                    font-size: 30px;
                    background-color: #D69BB7;
                }

                h6 {
                    font: normal normal medium 24px/28px Ubuntu;
                    font-size: 22px;
                    letter-spacing: 0px;
                    color: #262C2C;
                    padding-top: 1rem;
                }

                p {
                    font: normal normal medium 14px/16px Ubuntu;
                    letter-spacing: 0px;
                    color: #D69BB7;
                }
            }
        }

        .table-main {
            flex: 1 1 600px;
            background: white;
            padding: 20px;

            th {
                white-space: nowrap;
                font-size: 14px;
                padding: 5px;
            }
        }
    }
}