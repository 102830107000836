.LiquidationWalkin {
    .section--1 {
        padding-bottom: 5rem; 
        border-bottom: 1px solid #dcdacb;
        box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);

        .heading {
            background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
            padding:15px;
            border-radius: 5px;
            color: white;
        
        p{
            font-size: 18px;
            font-weight: bold;
         }
        }

        .button--double {
            width: 90%;
            margin: 0 auto;
            justify-content: center;
            gap: 8rem;
            padding: 2rem 0 1rem 0;

            .flex--3 {
                align-items: center;
            }

            button {
                border-radius: 50%;
                padding: .5rem 1rem;
                border: 3px solid #D0021B;
                width: 50px;
                font: normal normal medium 24px/28px Ubuntu;
                font-size: 20px;
                letter-spacing: 0px;
                color: #262C2C;
                // border-bottom: 1px solid #dcdacb;
                box-shadow: 0 5px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);
                transition: all .2s ease-in;
                margin-bottom: .5rem;

                &:hover {
                    box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
                }

                &:active {
                }

                &:focus {
                }
            }

            .active-1 {
                background-color: #FFEAED;
                color: #D0021B;
                box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
            }
            
            .active-2 {
                background-color: #FFEAED;
                color: #D0021B;
                box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
            }



        }

       
        .button-last {
            width: 90%;
            margin: 0 auto;
            display: flex;
            position: relative;
            left:55%;

            button {
                background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 6px 16px #B522684D;
                border-radius: 5px;
                font: normal normal bold 16px/18px Ubuntu;
                letter-spacing: 0px;
                color: #FFFFFF;
                width: 180px;
                height: 44px;
                margin-top: 2rem;
                margin-left: auto;

            }
        }
    }
        .transaction-grid{
            select {
                width: 100%;
                height: 44px;
                margin-left: 10px;
                border-radius: 5px;
                margin: 4px 0;
              }
              input {
                width: 100%;
                height: 44px;
                margin-left: 10px;
                border-radius: 5px;
                margin: 4px 0;
              }  
              label{
                 margin: 0px; 
              }
              .radiodiv {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 300px;
                .productradio {
                  min-width: 0;
                  height: 15px;
            
                  border-radius: 0;
                  margin: 0;
                }
                .span {
                  position: relative;
                  top: 5px;
                }
              }
              .next{
                background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;  
                color:white;
                position:relative;
                width:100px;
                height:44px;
                border-radius: 7px;
                margin-top: 40px;
                left:90%;
                margin-bottom: 30px;
                
        }
        }
        .sub-main{
            .sect1{
                display: flex;
                justify-content: space-between;
                padding:25px;
                background-color: grey;
            
            }         

            input {
                            height: 44px;
                            background: #FFFFFF 0% 0% no-repeat padding-box;
                            border: 1px solid #707070;
                            border-radius: 6px;
                            margin: 0 1rem;
                            width: 60%;
                            max-width: 130px;
                            text-align: center;
                        } 
            
        }
}
.span1 {
    display: flex;
    justify-content: center;
    padding-left: 39px;
}    

.second{
    padding-right: 40px;
}
