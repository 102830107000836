.portfolio--maintenance {
    .heading {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        padding: 15px 1.5rem;
        font-size: 18px;
        border-radius: 5px;
        font: normal normal bold 18px/28px Ubuntu;
        letter-spacing: 0px;
        color: #FFFFFF;

    }

    select {
        padding-left: 10px;
    }

    .section--1 {
        padding: 3rem 2rem;
        border-bottom: 1px solid #dcdacb;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 4px 12px rgba(0, 0, 0, .08);

        .button--double {
            width: 90%;
            margin: 0 auto;
            justify-content: center;
            gap: 8rem;
            padding: 0 0 2rem 0;

            .flex--3 {
                grid-gap: 1rem;
            }

            button {
                border-radius: 50%;
                padding: .5rem 1rem;
                border: 3px solid #D0021B;
                width: 50px;
                font: normal normal medium 24px/28px Ubuntu;
                font-size: 20px;
                letter-spacing: 0px;
                color: #262C2C;
                // border-bottom: 1px solid #dcdacb;
                box-shadow: 0 5px 4px rgba(0, 0, 0, .08), 0 4px 12px rgba(0, 0, 0, .08);
                transition: all .2s ease-in;
                margin-bottom: .5rem;

                &:hover {
                    box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
                }

                &:disabled {
                    cursor: not-allowed;
                    background-color: #acacac;
                }

                &:active {}

                &:focus {}
            }

            .active-1 {
                background-color: #D0021B;
                color: #ffffff;
                box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
            }

            .active-2 {
                background-color: #FFEAED;
                color: #D0021B;
                box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
            }
        }
    }

    .sub-main--1 {
        padding: 0 1rem;
        grid-gap: 1rem;
        flex: 1 1 400px;
        flex-wrap: wrap;

        .flex--input {
            input {
                height: 44px;
                min-width: 100px;
                max-width: 250px;
                border-radius: 5px;
            }

            select {
                height: 44px;
                width: 170px;

                min-width: 100px;
                max-width: 200px;
                border-radius: 5px;
            }
        }

        .flex--inputind {
            input {
                height: 44px;
                width: 500px;
                //  max-width: 500px;
                border-radius: 5px;
            }
        }

        .flex--inputindv2 {
            input {
                height: 44px;
                width: 300px;
                // min-width: 100px;
                // max-width: 250px;
                border-radius: 5px;
            }
        }

        .flex--inputind3 {
            input {
                height: 44px;
                min-width: 100px;
                max-width: 150px;
                border-radius: 5px;
            }
        }

        .flex--currency {
            select {
                height: 44px;
                min-width: 100px;
                max-width: 100px;
                border-radius: 5px;
            }
        }
    }

    .sub-main--2 {
        margin-top: 3rem;
        padding: 0 1rem;
        grid-gap: 1rem;
        flex: 1 1 400px;
        flex-wrap: wrap;

        .corporate {
            border-radius: 5px;
            background-color: rgb(242, 210, 215);
            color: red;

            p {
                padding: 13px 1rem;
            }
        }

        .checboxdiv {
            margin-top: 1rem;

            .span {
                position: relative;
                top: 3px;

            }
        }
    }

    .sub-main--3 {
        margin-top: 1rem;
        padding: 0 1rem;
        grid-gap: 1rem;
        flex: 1 1 400px;
        flex-wrap: wrap;

        .flex--inputind3 {
            input {
                height: 44px;
                width: 200px;
                min-width: 150px;
                max-width: 250px;
                border-radius: 5px;
            }
        }

        .flex--input {
            input {
                height: 44px;
                min-width: 150px;
                max-width: 250px;
                border-radius: 5px;
            }

            select {
                height: 44px;
                width: 200px;
                min-width: 150px;
                max-width: 200px;
                border-radius: 5px;
            }
        }
    }

    .main--2 {
        margin-top: 3rem;
        padding: 0 1rem;
        grid-gap: 1rem;
        flex: 1 1 400px;
        flex-wrap: wrap;

        .corporate {
            border-radius: 5px;
            background-color: rgb(242, 210, 215);
            color: red;

            p {
                padding: 13px 1rem;
            }
        }

        .checboxdiv {
            margin-top: 1rem;

            .span {
                position: relative;
                top: 3px;

            }
        }
    }

    .sub-main--4 {
        margin-top: 1rem;
        padding: 0 1rem;
        grid-gap: 1rem;
        flex: 1 1 400px;
        flex-wrap: wrap;

        .flex--inputind3 {
            input {
                height: 44px;
                width: 200px;
                min-width: 150px;
                max-width: 250px;
                border-radius: 5px;
            }

            select {
                height: 44px;
                width: 200px;
                min-width: 150px;
                max-width: 200px;
                border-radius: 5px;
            }

            .flex--input {
                input {
                    height: 44px;
                    min-width: 150px;
                    max-width: 250px;
                    border-radius: 5px;
                }

                select {
                    height: 44px;
                    width: 200px;
                    min-width: 150px;
                    max-width: 200px;
                    border-radius: 5px;
                }
            }
        }

    }

    .savediv {
        margin-top: 3rem;
        height: 44px;
        margin-bottom: 2rem;

        .save {
            height: 44px;
            width: 135px;
            margin: 0 0 0 auto;
            background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
            color: white;
            border-radius: 7px;
            font: normal normal bold 16px/18px Ubuntu;
        }
    }

    .sub--section {
        position: relative;
        right: 0;


        .main-filter {
            margin: 1rem 0 2rem auto;

            .filter {
                border: 1px solid #B52268;
                border-radius: 5px;
                width: 30px;
                min-width: 150px;
                height: 44px;
                padding: 0 0 0 1rem;
            }

            button {
                background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
                border-radius: 5px;
                font: normal normal bold 16px/18px Ubuntu;
                letter-spacing: 0px;
                color: #FFFFFF;
                width: 160px;
                height: 44px;
                margin: 0 2rem;

                &:nth-child(3) {
                    background: transparent;
                    font: normal normal bold 16px/18px Ubuntu;
                    color: #D0021B;
                    border: 2px solid #D0021B;
                    width: 200px;
                    margin-right: 0;
                }

            }
        }
    }

    .nextdiv {
        margin-top: 3rem;
        height: 44px;
        justify-content: flex-end;



        .next {
            height: 40px;
            width: 120px;
            background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
            color: white;
            border-radius: 7px;

        }
    }

    .section2 {
        margin-top: 3rem;
        padding: 0 1rem;
        grid-gap: 1rem;
        flex: 1 1 400px;
        flex-wrap: wrap;

        .corporate {
            margin-top: 2rem;
            border-radius: 5px;
            background-color: rgb(242, 210, 215);
            color: red;
            width: fit-content;

            p {
                padding: 13px 1rem;
            }
        }

        .checboxdiv {
            margin-top: 2rem;
            grid-gap: 5rem;

            .sub--checboxdiv {
                .span {
                    position: relative;
                    top: 3px;
                }
            }
        }

        .sub--section2 {
            margin-top: 1rem;
            grid-gap: 1rem;
            flex: 1 1 400px;
            flex-wrap: wrap;

            .flex--input {
                input {
                    height: 44px;
                    width: 200px;
                    min-width: 150px;
                    max-width: 300px;
                    border-radius: 5px;
                }

                select {
                    height: 44px;
                    width: 180px;
                    min-width: 150px;
                    max-width: 300px;
                    border-radius: 5px;
                }
            }
        }

        .decisiondiv {
            margin-top: 3rem;
            height: 44px;
            justify-content: flex-end;
            grid-gap: 1rem;

            .decision {
                height: 40px;
                width: 120px;
                background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
                color: white;
                border-radius: 7px;

            }
        }
    }

    .section--1{
        .deal-post{
            grid-gap: 1rem;
            flex-wrap: wrap;
            margin-bottom: 10px;
            .flex--3 {
                flex: 1 1 150px;
              }
              
              .input--1 {
                max-width: 150px;
              }
            
              .input--2 {
                min-width: 200px;
                max-width: 500px
                
              }
            //   .input--3{
            //     max-width: 300px;
            //   }
            //   .input--4{
            //     max-width: 200px;
            //   }
            //   .input--5{
            //     max-width: 135px;
            //   }
              input, select{
                min-width: 10px;
                height:44px;
                border-radius:5px;
              }
              label{
                  margin-bottom: 0;
              }
        }
    } 
    .updatebutton{
        background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        border-radius: 5px;
        font: normal normal bold 16px/18px Ubuntu;
        letter-spacing: 0px;
        color: #FFFFFF;
        width: 160px;
        height: 44px;
        margin: 0 2rem;
    } 
}