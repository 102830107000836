.account--opening {
    .section--1 {
        padding-bottom: 5rem; 
        border-bottom: 1px solid #dcdacb;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 4px 12px rgba(0, 0, 0, .08);

        .heading {
            background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
            padding: 15px;
            border-radius: 5px;
        }

        .heading p {
            color: #ffffff;
            font-size: 18px;
            font-weight: bold;
        }

        .button--double {
            width: 90%;
            margin: 0 auto;
            grid-gap: 2rem;
            flex-wrap: wrap;
            padding: 2rem 0 1rem 0;
            justify-content: center;

            .flex--3 {
                align-items: center;
            }

            button {
                border-radius: 50%;
                padding: .5rem 1rem;
                width: 48px;
                height: 45px;
                font: normal normal medium 24px/28px Ubuntu;
                font-size: 20px;
                letter-spacing: 0px;
                color: #262C2C;
                box-shadow: 0 5px 4px rgba(0, 0, 0, .08), 0 4px 12px rgba(0, 0, 0, .08);
                transition: all .2s ease-in;
                margin-bottom: .5rem;

                &:hover {
                    box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
                }

                &:disabled {
                    cursor: not-allowed;
                    background-color: #acacac;
                }
            }

            .active-1 {
                background-color: #D0021B;
                color: #ffffff;
                box-shadow: 0px 0px 2px 5px rgb(124, 124, 124);
            }

            .active-2 {
                background-color: #FFEAED;
                color: #D0021B;
                box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
            }


        }

        .button-switch {
            width: 90%;
            margin: 0 auto;
            justify-content: flex-end;
            grid-gap: 1rem;
        }

        .next,
        .previous {
            background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
            color: white;
            position: relative;
            width: 135px;
            height: 44px;
            border-radius: 7px;
            margin-top: 40px;
            margin-bottom: 30px;
        }

        .sub-main {
            .sect1 {
                display: flex;
                justify-content: space-between;
                padding: 25px;
                background-color: grey;
            }

            input {
                height: 46px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                border-radius: 6px;
                margin: 0 1rem;
                width: 100%;
                max-width: 150px;
                text-align: center;
            }
        }
    }

}