.teller--transactions {
    .heading{
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
        padding:15px;
        border-radius: 5px;
        color: white;
    
    p{
        font-size: 18px;
        font-weight: bold;
        
    }
}
    .sub--section{
        small{
            color: red;
            margin-bottom: -0.5rem;
        }
    } 
    .sub-main--input{
        // grid-gap: 1rem;
        margin-right: 10px;


    }
    .sub-main--input--2{
        margin-top: 20px;
    }
    label{
        margin-bottom:4px;
    }
}

