.investment--booking {

  .headerdiv {
    background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
    padding: 15px;
    border-radius: 5px;
    color: white;

    p {
      font-weight: bold;
      font-size: 18px
    }
  }

  input {
    height: 44px;
    margin-left: 10px;
    border-radius: 5px;
    margin: 4px 0;
  }

  select {
    height: 44px;
    margin-left: 10px;
    border-radius: 5px;
    margin: 4px 0;
    padding-left: 10px;
  }


  .checboxdiv {
    grid-gap: 7px;
    align-self: center;

    .productcheckbox {
      height: 15px;
      margin-left: 0;
      border-radius: 0;
      margin: 0;
      position: relative;
      top: 7px;
    }

    .span {
      position: relative;
      top: 7px;
    }

  }

  .checkbtn {
    width: 130px;
    height: 44px;
    border: 2px solid white;
    color: white;
    border-radius: 5px;
    background-color: #D0021B;
  }

  .btnz {
    background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
    color: white;
    position: relative;
    width: 130px;
    height: 44px;
    border-radius: 7px;
    margin-top: 30px;
  }

  .main-container {
    .flex--3 {
      flex: 1 1 150px; 
      max-width: 250px;
    }

    .investmentProduct {
      flex: 1 0 200px;
    }
    
    .input--1 {
      min-width: 200px;
      max-width: 300px;
    }
  }

  label {
    margin: 0;
    padding: 0;
  }

  .next-section {
    padding: 1rem 0;
    grid-gap: 1rem;

    .flex--3 {
      flex: 1 1 10px;
    }

    .credit--to {
      max-width: 150px;
      height: 100px;
    }
  }





  .section-table {
    // border-top: 2px solid black;
    // padding: 2rem 0;
    margin: 0 2rem;
  }

  .input--amount {
    margin-top: 4px;
  }

  .section--2 {
    margin: 1rem 0;

    .button {
      align-self: flex-end;
      margin-bottom: 2px;

      button:disabled,
      button[disabled]{
        background: #e6a6a6;
        cursor: no-drop;
      }
    }

    button {
      background: transparent linear-gradient(141deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
      border-radius: 5px;
      width: 100px;
      height: 44px;
      color: white;
    }
  }

  

}