.customer--info {
  flex-wrap: wrap;
  grid-gap: 1rem;
  padding: 40px;

  .flex--3 {
    flex: 1 1 200px;
    max-width: 400px;
  }

  .signature {
    flex: 1 1 350px; 
    min-width: 370px;
  }


  select {
    height: 44px;
    margin-left: 20px;
    border-radius: 5px;
    margin: 4px 0;
    min-width: 200px;
    padding-left: 10px
  }

  input {
    height: 44px;
    padding-left: 10px;
    margin-left: 10px;
    border-radius: 5px;
    margin: 4px 0;
    min-width: 200px;
  }

  label {
    margin: 0;
  }

  .icon--error {
    transform: translateY(-5px);
  }

  input[type = "file"]{
    padding: 6px 10px;
    background-color: #e6c9ce;
    border: none;
    // color: white
  }
}