.loanschedule-details{
    

    .headerdiv{
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
        padding:15px;
        border-radius: 5px;
        color: white;
    
   p{
       font-size: 18px;
       font-weight: bold;
   } 
}
   
     .button--double {
            width: 100%;
            margin: 0 auto;
            justify-content: center;
            gap: 5rem;
            padding: 0 0 2rem 0;

            .flex--3 {
                align-items: center;
                margin-top:30px;
                .button-name{
                    color:black;
                }
   
            }

            button {
                border-radius: 50%;
                padding: .5rem 1rem;
                border: 3px solid #D0021B;
                width: 48px;
                height:44px;
                font: normal normal medium 24px/28px Ubuntu;
                font-size: 20px;
                letter-spacing: 0px;
                color: #262C2C;
                // border-bottom: 1px solid #dcdacb;
                box-shadow: 0 5px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);
                transition: all .2s ease-in;
                margin-bottom: .5rem;

                &:hover {
                    box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
                }

                &:active {
                }

                &:focus {
                }
            }

            .active-1 {
                background-color: #FFEAED;
                color: #D0021B;
                box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
            }
            
            .active-2 {
                background-color: #FFEAED;
                color: #D0021B;
                box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
            }



        } 
        label{
            margin-left: 0px;
            margin-bottom: 0px;
        }
        .empty--label{
            color:white;
        }
        .empty{
            margin-left: 35px;
            
            margin-top: 10px;
        }
        input,select{
         min-width: 100%;
         height:44px;
         margin-left: 10px;
         border-radius: 5px; 
         margin: 4px 0; 
         width: 100%;
        //  max-width: 400px;
        }
    //     select{
    //      min-width: 345px;
    //      // width: 100%;
    //      height:44px;
    //      margin-left: 10px;
    //      border-radius: 5px;
    //      margin:10px 0;
     
    //  }
    //  .select--input{
    //      min-width: 170px;
    //  }
     .previous{
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;  
      color:white;
      position:relative;
      width:180px;
      height:44px;
      border-radius: 7px;
      margin-top: 40px;
      left:70%;
      margin-bottom: 30px;  
     }
     .next{
      background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;  
      color:white;
      position:relative;
      width:180px;
      height:40px;
      border-radius: 7px;
      margin-top: 40px;
      left:80%;
      margin-bottom: 25px;
  }
  .sub-main{
    .sect1{
        display: flex;
        justify-content: space-between;
        padding: 25px;
        background-color: grey;
        width: 100%;
    }
    input {
                    height: 46px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 1px solid #707070;
                    border-radius: 6px;
                    margin: 0 1rem;
                    width: 60%;
                    max-width: 150px;
                    text-align: center;
                    min-width: 30px;
                } 
}
   
.button-last {
    width: 90%;
    margin: 0 auto;
    display: flex;
    position: relative;
    left:55%;

    button {
        background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 6px 16px #B522684D;
        border-radius: 5px;
        font: normal normal bold 16px/18px Ubuntu;
        letter-spacing: 0px;
        color: #FFFFFF;
        width: 180px;
        height: 44px;
        margin-top: 2rem;
        margin-left: auto;

    }
}
}