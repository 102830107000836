.mc--issuance {
  color: white;

  .headerdiv {
    background: transparent linear-gradient(137deg, #d0021b 0%, #68010e 100%) 0%
      0% no-repeat padding-box;
    padding: 7px;
    border-radius: 5px;
    height: 50px;
  }
  p {
    padding-top: 10px;
    padding-left: 20px;
  }
  select {
    min-width: 100%;
    height: 44px;
    margin-left: 10px;
    border-radius: 5px;
    margin: 10px 0;
  }
  label {
    margin-left: 0px;
  }
  input {
    min-width: 100%;
    height: 44px;
    margin-left: 10px;
    border-radius: 5px;
    margin: 10px 0;
  }
  .checboxdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 170px;
    .productcheckbox {
      min-width: 0;
      height: 15px;
      margin-left: 0;
      border-radius: 0;
      margin: 0;
    }
    .span {
      position: relative;
      top: 5px;
      right: 110px;
    }
  }
  .radiodiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    .productradio {
      min-width: 0;
      height: 15px;

      border-radius: 0;
      margin: 0;
    }
    .span {
      position: relative;
      top: 5px;
    }
  }
  .addbtn{
      background-color:#d0021b ;
      color:white;
      position: relative;
      align-items: center;
      width: 56px;
      height: 33px;
      border-radius: 5px;
      left: 40px;
      bottom: -30px;
  }
  
  .tablediv{
      width:80%;
      border:1px solid grey;
      height: 200px;
      margin: 50px auto;
      background-color: whitesmoke;
      section{
          background-color: grey;
      .totaldiv{
          width:85px;
          display:flex;
          justify-content: space-between;
          align-items: center;
          position:relative;
          left:418px;
          input{
              width:100px;
              height:30px;
          }
      }
    }
      table {
        width: 100%;
        font: 14px;
        white-space: nowrap;
    }

    td, th {
        text-align: center;
        padding: 8px;
        border: none;
    }

    tr:nth-child(even) {
        background-color: #8f8c8c;
    }
    .table--icon {
        margin-left: 1rem;
        background-color: white;
        box-shadow: 2px 2px 2px 2px rgb(230, 225, 225);
        height:30px;
        cursor: pointer;

        &--2 {
            margin-left: .5rem;
        }
    }
      input{
          width:5px;
          margin-left: 10px;
      }

  }
}
