.success--main {
    background-color: rgb(71, 202, 93);
    font: normal normal normal 14px/18px Ubuntu;
    letter-spacing: 0px;
    color: #ffffff;
    padding: 2rem 4rem;
    border-radius: 5px;
    width: 40%;
    text-align: center;

    p {
        font-size: 20px;
    }
}

.error-msg {
    background-color: rgb(245, 49, 49);
}

.error-input-z {
    border: 2px solid red !important;
    border-radius: 5px;

    &:focus {
        border: 2px solid red;
    }
}

.error-container-z {
    .icon--error {
        // padding-top: -2rem;
        transform: translateY(-2rem)
    }

    .error--p {
        color: #d93025;
        position: absolute;
        left: 0;
        bottom: 11px;

        .icon {
            font-size: 1rem;
            margin-right: .5rem;
            margin-top: -22px;
        }

        small {
            font-size: 12px;
            padding-top: .1rem;
        }
    }


    .error--icon {
        color: red;
        font-size: 14px;
        margin-top: .7rem;
    }

    .error--both {
        font-size: 14px;
        white-space: nowrap;
        color: red;
        padding: .7rem .5rem 0;
    }

    position: relative;

}

.error--border {
    border: 2px solid red;
}

.table--main {
    min-width: 400px;
}

.table--heading {
    white-space: nowrap;
}

.text--align {
    text-align: center;
}

.cursor--pointer {
    cursor: pointer;
}

.cursor--not--allowed {
    cursor: not-allowed;
}

.updater--box {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(141, 141, 141, 0.213);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    color: rgba(255, 255, 255, 1);
    overflow-y: hidden;
}

.update--content {
    background: white;
    width: 80%;
    border: none;
    border-radius: 10px;
    overflow-y: scroll;
    padding: 2rem;
    max-height: 500px;
    color: #262C2C;
    position: relative;

    .spacer {
        margin-top: 3rem;
    }

    .close-box {
        position: absolute;
        top: 20px;
        right: 30px;
        font-size: 20px;
        color: rgb(61, 61, 61);
        cursor: pointer;
    }


    #savebtn {
        border-bottom: none;
    }

    label {
        color: black
    }

}

#button--cont {
    justify-content: center;
    display: flex;

    button {
        width: 160px;
        height: 44px;
        border-radius: 5px;
        font: normal normal normal 16px/18px Ubuntu;
    }

    button:first-child {
        background: transparent;
        margin-left: 19px;
        border: 2px solid #D0021B;
        background-color: white;
        font: normal normal medium 18px/21px Ubuntu;
        color: #D0021B;
        box-shadow: none;
        margin-right: 1rem;
    }

    #button {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        color: white;
        border: none;
    }

}

.button-sub {
    margin-top: 1.5rem;
}

.input-disable {
    pointer-events: none;
    cursor: not-allowed;
    border: 1px solid grey;
    background-color: rgb(238, 238, 238);
    height: 40px;
    padding: 25px;
    border-radius: 5px;
}

.input-disable--2 {
    pointer-events: none;
    cursor: not-allowed;
    border: 1px solid grey;
    background-color: rgb(245, 243, 243);
}

.input--height {
    height: 44px;
    border: 2px solid red;
}

.view-container {
    padding: 2rem;
    width: 100%;
    max-width: 1000px;
    margin: 0 2rem;
    background: white;
    border: none;
    padding: 3rem;
    border-radius: 10px;
    overflow-y: scroll;
    padding: 2rem;
    max-height: 70vh;
    color: #262C2C;
    position: relative;


    h6 {
        text-align: center;
        font-weight: bold;
    }

    .view-body {
        color: rgb(29, 29, 29);
        flex-wrap: wrap;
        grid-gap: 1rem 26px;
        padding-bottom: 2rem;
        margin-top: 2px;



        label {
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 0;
            white-space: nowrap;
        }

        p, .formatted--text {
            background-color: rgb(242, 216, 220);
            border: 1px solid rgb(184, 184, 184);
            outline: none;
            font-size: 14px;
            border-radius: 4px;
            padding: 7px 10px;
        }


        .label-paragraph {
            justify-content: flex-start;
            width: 200px;
        }

        // .label-paragraph:last-child {

        // }
    }

    button {
        width: 100px;
        // min-width: 137px;
        height: 44px;
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        color: white;
        border-radius: 5px;
    }
}

.auth-box {
    width: 40%;
    max-width: 600px;
    min-width: 450px;
}

.auth-body {
    width: 100%;
    flex-wrap: wrap;

    input,
    select {
        width: 100%;
        border: 1px solid rgb(26, 25, 25);
        max-width: 500px;
        min-width: 100px;
        height: 44px;
        margin-left: 10px;
        border-radius: 5px;
        margin: 0px 0 13px;
    }
}

input,
select {
    outline: none;
}

.main-filter {
    margin: 0rem 0 1rem auto;


    input {
        border-radius: 5px;
        width: 300px;
        height: 50px;
        padding: 0 0 0 1rem;
    }

    #input {
        min-width: 10px;
    }

    button {
        background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        border-radius: 5px;
        font: normal normal bold 16px/18px Ubuntu;
        letter-spacing: 0px;
        color: #FFFFFF;
        width: 160px;
        height: 44px;
        margin: 0 0 0 1.5rem;

        &:nth-child(3) {
            background: transparent;
            font: normal normal bold 16px/18px Ubuntu;
            color: #D0021B;
            border: 2px solid #D0021B;
            width: 200px;
            margin-right: 0;
        }

    }
}

.searchable-dropdown {
    .css-g1d714-ValueContainer {
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        -webkit-box-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 2px 8px;
        -webkit-overflow-scrolling: touch;
        position: relative;
        overflow: hidden;
        height: 40px;
        box-sizing: border-box;


    }
    
    .css-1uccc91-singleValue {
        top: 55%;
        left: 1rem;
    }
}
//     height: 42px;
//     // z-index: 2 !important;
//     cursor: pointer;

//     .css-1hwfws3 {
//         padding: 0 8px 0;
//     }

//     .css-yk16xz-control,
//     .css-g1d714-ValueContainer {
//         height: 41px;

//     }

//     .css-yk16xz-control {
//         border: none;
//         outline: none;
//     }

//     .css-1uccc91-singleValue {
//         top: 67%;
//     }

//     .css-1okebmr-indicatorSeparator {
//         display: none;
//     }

//     .css-tlfecz-indicatorContainer {
//         color: black
//     }

//     .css-1wa3eu0-placeholder {
//         color: rgb(0, 0, 0);
//         margin-left: 2px;
//         margin-right: 2px;
//         position: absolute;
//         top: 50%;

//         &:focus,
//         &:active {
//             top: 66%;
//         }
//     }

// }

.border--norm {
    border: 1px solid #181818;
    border-radius: 5px;
    margin-top: 4px;
}


.nowrap {
    white-space: nowrap;
    width: 180px;
}

.sub--main {
    flex-wrap: wrap;
    grid-gap: 1rem;

    .code-container {
        &--1 {
            width: 130px;
        }

        &--2 {
            width: 180px;
        }

        &--3 {
            width: 200px;
        }
    }

    .product-name {
        padding: 10px;
    }
}

.section--table {
    // border-top: 2px solid rgb(31, 31, 31);
    margin: 0 2rem;
   
}

.headerdiv, .heading {
    background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
    padding: 15px;
    border-radius: 5px;
    color: white;

    p {
        font-size: 18px;
        font-weight: bold;
    }
}

.signed--out {
    background: white;
    border-radius: 10px;
    padding: 2rem;
    color: rgb(63, 62, 62);
    align-items: center;
    grid-gap: 1.5rem;

    .icon {
        font-size: 56px;
        color: #ac0116;
    }

    h1 {
        font-size: 18px;
        text-align: center;
        color: rgb(17, 17, 17)
    }

    button {
        box-shadow: 0px 3px 5px #B522684D;
        border-radius: 5px;
        width: 100px;
        height: 40px;
        font: normal normal bold 14px/18px Ubuntu;
        letter-spacing: 0px;
        color: #FFFFFF;
        background: transparent linear-gradient(138deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
    }
}

.disabled-delete {
    pointer-events: none;
    color: rgb(112, 112, 112)
}

.sub--section-z {
    margin-bottom: 1rem;
}

.main-filter-z {
    margin: 0rem 0 0rem auto;

    input {
        margin: 0rem 0 0rem auto;
        border-radius: 5px;
        width: 30%;
        min-width: 20px;
        height: 44px;
        padding: 0 0 0 5px;
    }

    button {
        background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        border-radius: 5px;
        font: normal normal bold 16px/18px Ubuntu;
        letter-spacing: 0px;
        color: #FFFFFF;
        width: 160px;
        height: 44px;
        margin: 0 2rem;

        &:nth-child(3) {
            background: transparent;
            font: normal normal bold 16px/18px Ubuntu;
            color: #D0021B;
            border: 2px solid #D0021B;
            width: 200px;
            margin-right: 0;
        }

    }
}