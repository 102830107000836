.login {
    font-size: 1rem;
    height: 100vh;
    padding: 3rem 5rem;
    position: relative;
    justify-content: space-between;


    %label-input {
        label {
            margin-bottom: 3px;
            letter-spacing: 0px;
            color: #262C2C;
        }

        input, select {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #707070;
            border-radius: 5px;
            width: 100%;
            // max-width: 400px;
            // min-width: 250px;
            height: 46px;
            color: rgb(65, 64, 64);
            padding-left: 1rem;

        }
    }

    .login-button{
        background: #D0021B 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 5px #aaa3a65c;
        border-radius: 5px;
        max-width: 430px;
        min-width: 300px;
        width: 100%;
        height: 48px;
        font: normal normal bold 16px/18px Ubuntu;
        color: #FFFFFF;
        transition: all .2s ease-in;

        &:hover {
            box-shadow: 1px 5px 10px #aaa3a65c;
        }

    }

    h6{
        cursor: pointer;
    }


    .icon--error {
        margin: -34px 0 10px 0;
    }
    

    .ovelay {
        background-image: linear-gradient(rgba(141, 141, 141, 0.4), rgba(106, 106, 106, 0.4));  
        position: fixed;
        z-index: -1;
        height: 100%;
        width: 100vw;
        top: 0;
        left: 0;
        display: none;

    }

    .background-lady {
        position: absolute;
        z-index: -2;
        right: 0;
        height: 100vh;
        width: auto;
        // min-width: 59vw;
    }
    
    img {
        width: 170px;

        &:nth-child(1) {
            padding: 3rem 0 0;
        }

        &:nth-child(3) {
            padding: 3rem 0 0rem;
        }

    .section--1 {
        height: 100vh;

        .white--paper{
            // display: flex;
            // justify-content: center;            
            .change--password{
                margin-bottom: 1rem;
                margin-left: 16rem;
                width: 20%;

            }
            .forgot--paragraph{
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 1rem;
                text-align: center;
            }
            .forgot--paragraph__1{
                margin-bottom: 1rem;
                text-align: center;
            }
            .forget--password{
                @extend %label-input;
                .buttons{
                    margin-top: 1.5rem;
                    .cancel--button{
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        box-shadow: 0px 3px 6px #0000003D;
                        border-radius: 5px;
                        opacity: 1;
                        padding: 0.5rem;
                        width: 20%;
                        color: #C2000A;
                    }

                    .reset--button{
                        background: #C2000A 0% 0% no-repeat padding-box;
                        box-shadow: 0px 3px 6px #0000003D;
                        border-radius: 5px;
                        opacity: 1;
                        padding: 0.5rem;
                        margin-left: 2rem;
                        width: 20%;
                        color: #FFFFFF;
                    }
                }
                
            }
        }



        }
        
        .sub--section {
            width: 400px;
            margin: 7rem 0 1rem;
            position: relative;

            .forgot-password--button{
                background: none;
                margin-left: 16rem;
                margin-bottom: 1rem;
                // margin: -1.5rem 0 0 auto;

            }

            h1 {
                font: normal normal bold 35px/55px Ubuntu;
                letter-spacing: 0px;
                color: #262C2C;
                padding: 0 0 .5rem;
            } 

            input {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #17171a;
                border-radius: 5px;
                font: normal normal 400 16px/18px Ubuntu;
                letter-spacing: 0px;
                max-width: 430px;
                min-width: 300px;
                color: #FFFFFF;
                width: 100%;
                height: 48px;
                margin-bottom: 2rem;
                padding: 0 0 0 1rem;
                font: normal normal normal 16px/18px Ubuntu;
                letter-spacing: 0px;
                color: #262C2C;

                &:focus {
                    background: #f7f7f7 0% 0% no-repeat padding-box;
                    border: 2px solid #17171ac9;
                    color: #17171a;
                }
            }

            small,span {
                
                margin: -1.5rem 0 0 auto;
                // border: 1px red solid;
            }



        

        }

    }

    
    .section--2 {
        height: 100vh;
        padding: 13rem 0 0;

        h1 {
            font: normal normal bold 70px/110px Ubuntu;
            letter-spacing: 0px;
            color: #FFFFFF;
        }

        p {
            font: normal normal normal 24px/28px Ubuntu;
            color: #FFFFFF
        }
    }

    


    // .logo-noble {
    // }
}

// @media screen and (max-width: 1200px) {
//     .login {
//         font-size: 1rem;
//         height: 100vh;
//         // padding: 3rem 2rem;
//     }
// }


@media screen and (max-width: 800px) {
    .login {
        font-size: 1rem;
        height: 100vh;
        padding: 3rem 2rem;
        // border: 2px solid red

        .ovelay {
            display: inline-block;
        }
    
    }

    .login .section--2 {
        display: none;
    }

    .login .section--1 {
        width: 100%;
    }

    .login .section--1 .sub--section {
        width: 100%;
        margin: 7rem 0 1rem;
    }

    .login .section--1 .sub--section input {
        max-width: 100%;
        min-width: 300px;
    }

    .login .section--1 .sub--section button {
        max-width: 100%;
        min-width: 300px;
        width: 100%;
    }

    
}