.account--signatories {
  input {
    min-width: 400px;
    height: 44px;
    margin-left: 10px;
    border-radius: 5px;
    margin: 4px 0 12px;
  }

  select {
    min-width: 400px;
    height: 44px;
    margin-left: 10px;
    border-radius: 5px;
    margin: 4px 0 12px;
  }

  label {
    margin-bottom: -0.3rem;
  }

  .checboxdiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 1rem 0 2rem;

    .productcheckbox {
      min-width: 0;
      height: 15px;
      margin-left: 0;
      border-radius: 0;
      margin: 0;
      position: relative;
      top: 2px;
    }

    .checkbox-cont {
      grid-gap: 10px;
    }

    .span {
      position: relative;
    }

    .checkbtn {
      width: 85%;
      height: 35px;
      margin-left: 19px;
      border: 2px solid #d0021b;
      color: #d0021b;
      border-radius: 5px;
      background-color: white;
    }
  }

  .icon--error {
    transform: translateY(-12px);
  }

  .product--def {
    grid-gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 7px;

    .flex--3 {
      flex: 1 1 120px;
    }

    .input--1 {
      max-width: 120px;
    }

    .input--2 {
      min-width: 200px;
      max-width: 540px;
    }
    .input--3 {
      max-width: 212px;
    }
    .input--4 {
      max-width: 420px;
    }
    .input--5 {
      max-width: 135px;
    }
    input {
      min-width: 10px;
    }
    select {
      min-width: 10px;
    }
  }
  .dbtn {
    display:flex;
    align-items: center;
    justify-content: center;
    .btnd {
      border: 2px solid #d0021b;
      width: 100px;
      color: #d0021b;
      border-radius: 5px;
      background-color: white;
    }
  }
}
