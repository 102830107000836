.cash-deposit--lcy {
    .section--1 {
        padding-bottom: 5rem; 
        border-bottom: 1px solid #dcdacb;
        box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);

        // .heading {
        //     background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        //     padding: 15px;
        //     font-size: 18px;
        //     border-radius: 5px;
        //     font: normal normal bold 18px/28px Ubuntu;
        //     letter-spacing: 0px;
        //     color: #FFFFFF;
        //     margin-bottom: 3rem;
            
        // }

        .button--double {
            width: 90%;
            margin: 20px auto 0;
            justify-content: center;
            gap: 8rem;
            padding: 0 0 2rem 0;

            .flex--3 {
                align-items: center;
            }

            button {
                border-radius: 50%;
                padding: .5rem 1rem;
                border: 3px solid #D0021B;
                width: 50px;
                font: normal normal medium 24px/28px Ubuntu;
                font-size: 20px;
                letter-spacing: 0px;
                color: #262C2C;
                // border-bottom: 1px solid #dcdacb;
                box-shadow: 0 5px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);
                transition: all .2s ease-in;
                margin-bottom: .5rem;

                &:hover {
                    box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
                }

                // &:active {
                // }

                // &:focus {
                // }
            }

            .active-1 {
                background-color: #FFEAED;
                color: #D0021B;
                box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
            }
            
            .active-2 {
                background-color: #FFEAED;
                color: #D0021B;
                box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
            }

            
        }
        .flex--5{
            padding-right: 4.8rem;
            // width: 90%;
            //  margin: 0 auto;
            // display: flex;
            // justify-content: flex-start;
            // float: left;
            // border: 1px solid red;
            button {
                background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 6px 16px #B522684D;
                border-radius: 5px;
                letter-spacing: 0px;
                // position: relative;
                // left: 70%;
                color: #FFFFFF;
                width: 180px;
                height: 44px;
                margin-top: 2rem;
                margin-left: auto;
                margin-bottom: 25px;
            }
        }


        .product--def {
            // grid-gap: 1rem;
            // flex-wrap: wrap;
            // margin-bottom: 7px;
            // border: solid red 1px;
          
            .flex--3 {
            //   flex: 1 1 150px;
            }
            
            // .input--1 {
            //   max-width: 150px;
            // }
          
            // .input--2 {
            //   min-width: 200px;
            //   max-width: 500px
            // }
            // .input--3{
            //   max-width: 600px;
            // }
            // .input--4{
            //   max-width: 700px;
            // }
            // .input--5{
            //   max-width: 135px;
            // }
            // input {
            // //   min-width: 10px;
            // }
            label {
                letter-spacing: 0px;
                color: #262C2C;
                margin-bottom: 4px;
            }

            input {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                border-radius: 5px;
                height: 44px;
                color: rgb(65, 64, 64);
                padding-left: 1rem;
                width: 90%;
                // margin: 4px 0;

            }
            // .sub-input {
            //     align-self: flex-end;
            // }
          }

          .sub--section {
            position: relative;
            right: 0;
        }
            .main-filter {
                margin: 1rem 0 2rem auto;
              
                .filter  {
                    border: 1px solid #B52268;
                    border-radius: 5px;
                    width: 30px;
                    min-width: 150px;
                    height: 44px;
                    padding:  0 0 0 1rem;
                }
              
                button {
                    background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
                    border-radius: 5px;
                    font: normal normal bold 16px/18px Ubuntu;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    width: 160px;
                    height: 44px;
                    margin: 0 2rem;
              
                    &:nth-child(3) {
                        background: transparent;
                        font: normal normal bold 16px/18px Ubuntu;
                        color: #D0021B;
                        border: 2px solid #D0021B;
                        width: 200px;
                        margin-right: 0;
                    }
              
                }
              }
        %label-input {
            label {
               
                letter-spacing: 0px;
                color: #262C2C;
            }

            input {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                border-radius: 5px;
                width: 100%;
                max-width: 500px;
                // min-width: 250px;
                height: 44px;
                color: rgb(65, 64, 64);
                padding-left: 1rem;
                // margin: 4px 0;

            }
        }



        // .button-last {
        //     width: 90%;
        //     margin: 0 auto;
        //     display: flex;

        //     button {
        //         background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        //         box-shadow: 0px 6px 16px #B522684D;
        //         border-radius: 5px;
        //         font: normal normal bold 16px/18px Ubuntu;
        //         letter-spacing: 0px;
        //         color: #FFFFFF;
        //         width: 180px;
        //         height: 44px;
        //         margin-top: 2rem;
        //         margin-left: auto;
        //     }
        // }

        .sub-section {
            @extend %label-input;
           
            
            .container-main {
                gap: 2rem;
            }

            .input-container {
                width: 50%;
                gap: 2rem;
                padding-bottom: 2rem;

                &--1 {
                    gap: 2rem;

                    .sub-input {
                        align-self: flex-end;
                    }
                }

            }
        }

        .last-input {
            input {
                max-width: 100%;
            }
        }
    }




    .sub-main {
        border-bottom: 1px solid #dcdacb;
        box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);
        width: 90%;
        margin: 0 auto;

        &--1 {
            padding: 1rem 1rem;
            background: #B2B9B9 0% 0% no-repeat padding-box;
            border: 1px solid #707070;
            justify-content: center;
            gap: 7rem;
            white-space: nowrap;


            p {
                font: normal normal normal 16px/18px Ubuntu;
                letter-spacing: 0px;
                color: #FFFFFF;
                padding: 0 1rem;
            }

            input {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                border-radius: 3px;
                height: 30px;
                width: 100%;
                max-width: 250px;
                
            }
        }

        &--2 {
            .multiplier-input {
                padding: 1.2rem 2rem;
                
                input {
                    height: 44px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 1px solid #707070;
                    border-radius: 6px;
                    margin: 0 1rem;
                    width: 30%;
                    max-width: 150px;
                }

                span {
                    font-size: 1.5rem;
                }

            }
        }

        
    }
    
    .button-last--1 {
        width: 90%;
        margin: 0 auto;

        
        .button-container {
            margin-left: auto;
            gap: 3rem;
            margin-top: 2rem;

            button:first-child {
                background: transparent;
                border: 2px solid #D0021B;
                color: #D0021B;
            }
        }
    }
    tr, td{
        margin: 20px;
    }
}