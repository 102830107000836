.bulk--transaction{
    background-color: #ffffff;

    %label-input {
        label {
            margin-bottom: 3px;
            letter-spacing: 0px;
            color: #262C2C;
        }

        input, select {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #707070;
            border-radius: 5px;
            width: 30%;
            max-width: 300px;
            min-width: 150px;
            height: 46px;
            color: rgb(65, 64, 64);
            padding-left: 1rem;
            margin-bottom: 1rem;

        }
    }
    .section1 {
        margin-top: 50px;
        display: flex;
        grid-gap: 1rem;
        flex-wrap: wrap;
        
        .flex--3 {
            flex: 1 1 200px;
            max-width: 140px;
            .select--input-1{
                min-width: 120px;
            }

            .select--input-22{
                min-width: 120px;
            }
        }
        .deldiv{
            .delbtn{
              height:44px;
              background-color: white; 
              width:60px; 
              border:1px solid #D0021B;
              border-radius: 5px;
            }
        }
        .adddiv{
            display: flex;
            justify-content: space-between;
            width: 100%;

        .flex--btn {
            width: 50px;
            align-self: flex-end;

            .addbtn {
                border-radius: 60px;
                width: 45px;
                height: 30px;
                background-color: white;
                color: #d0021b;
                box-shadow: 2px 2px 2px 2px #ebacb3;
                margin-bottom: 8px;
            }

        }
    }
        .flex--ind {
            max-width: 47px;
            min-width: 40px;
            
            input {
                height: 44px;
                border-radius: 3px;
            }
        }
        
        .flex--ind2 {
            min-width: 80px;
            
            input {
                height: 44px;
                border-radius: 3px;
                max-width: 200px;
            }
        }

        
        .select--input {
            flex: 1 1;
            max-width: 100px;
            min-width: 100px;
            // border: 2px solid red;


        }

        select {
            height: 44px;
            border-radius: 3px;

        }

        input {
            height: 44px;
            border-radius: 3px;
            max-width: 140px;
        }
       .down-div-flex{
           display: flex;
           margin-top: 30px;
           .down-div-flex-1{
               margin-left: 45px
           }
       }
    }

    .btn {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        color: white;
        position: relative;
        width: 180px;
        height: 44px;
        border-radius: 7px;
        margin-top: 80px;
        left: 70%;
        margin-bottom: 25px;
    }
    .section--1{
        padding: 3rem 2rem;
        border-bottom: 1px solid #dcdacb;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 4px 12px rgba(0, 0, 0, .08);

       .togglediv{
            padding-left: 30%;
            height:30px;
            margin: 20px;
            height: 44px;
        
        .autobtn{
            margin-left: 30px;
        }
        .uploadbtn{
            border:1px solid #D0021B;
            background-color: white;
            height:44px;
            width: 200px;
            border-radius:7px;
            color:#D0021B;
            font-weight: bold;
          &:hover{
            background-color: #D0021B; 
            color:white;
          }  
        }
        .active{
          background-color: #D0021B; 
          color:white;   
        }
       } 
        .sub-main{
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 1rem;
            // border: 1px solid red;
            .sub-main--1{
                @extend %label-input;
            }
            .sub-main--2{
                // border: 1px solid red;
                justify-content: space-between;
                input{
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 1px solid #707070;
                    border-radius: 5px;
                    width: 30%;
                    max-width: 300px;
                    min-width: 250px;
                    height: 46px;
                    color: rgb(161, 106, 106);
                    padding-left: 1rem;
                    margin-bottom: 1rem;
                    margin-right: 1.5rem;
                }

                .file--buttons{
                    justify-content: space-between;
                }

                .upload--buttons{
                    // border: 1px solid red;
                    justify-content: space-between;
                    margin-right: 2rem;

                    button{
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        box-shadow: 0px 3px 6px #9801144A;
                        border-radius: 5px;
                        width:100%;
                        height: 46px;
                        margin-bottom: 1rem;
                        margin-right: 1rem;
                        color: #D0021B;
                        // border: 1px solid red;
                    }

                }
            }

        }

        .last--input{
            // border: 1px solid red;
            align-items: flex-end;
            justify-content: space-between;
            // margin-top: 2rem;
            .last{

                .last--one{
                    @extend %label-input;
                }
            }

            
            
        }
        .validate--buttons{
            justify-content: flex-end;
            button{
                background: #D0021B 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #9801144A;
                border-radius: 5px;
               // width:15%;
                height: 46px;
                // margin-top: 0.5rem;
                color: #FFFFFF;
                padding: 0.5rem;
            }
            .cancelbtn{
                background:white;
                border-color:#D0021B;
                color:#D0021B;
                margin-right: 20px;
            }
        }

        .download--button{
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #9801144A;
            border-radius: 5px;
            width:100%;
            height: 46px;
            margin-bottom: 1rem;
            color: #D0021B;
            padding: 0.5rem;
            text-decoration: none;
        }

    }
    

}