.sell--cash {
    .heading {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
        padding:15px;
        border-radius: 5px;
    }
    .heading p{
        color: white;
        font-size: 18px;
        font-weight: bold;
    }
    .section--1{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 6px 12px #00483E2E;
        padding-bottom: 5rem; 
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        border-radius: 5px;
        opacity: 1;
        
        

        .button--double {
            width: 90%;
            margin: 0 auto;
            justify-content: center;
            gap: 8rem;
            padding: 2rem 0 1rem 0;

            .flex--3 {
                align-items: center;
            }

            button {
                border-radius: 50%;
                padding: .5rem 1rem;
                border: 3px solid #D0021B;
                width: 50px;
                font: normal normal medium 24px/28px Ubuntu;
                font-size: 20px;
                letter-spacing: 0px;
                color: #262C2C;
                // border-bottom: 1px solid #dcdacb;
                box-shadow: 0 5px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);
                transition: all .2s ease-in;
                margin-bottom: .5rem;

                &:hover {
                    box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
                }

                // &:active {
                // }

                // &:focus {
                // }
            }

            .active-1 {
                background-color: #FFEAED;
                color: #D0021B;
                box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
            }
            
            .active-2 {
                background-color: #FFEAED;
                color: #D0021B;
                box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
            }



        }

        %label-input {
            label {
                margin-bottom: 3px;
                letter-spacing: 0px;
                color: #262C2C;
            }

            input, select {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                border-radius: 5px;
                width: 90%;
                // max-width: 400px;
                // min-width: 250px;
                height: 46px;
                color: rgb(65, 64, 64);
                padding-left: 1rem;

            }
        }
        .sub--section {
            // justify-content: space-between;
            // width: 90%;
            // margin: 0 auto;

            small{
                color:red
            }

            .sub--main-1 {


                .label--input {
                    padding: 0 0 1rem;
                    width: 400px;

                    @extend %label-input
                }

                .branch--input {
                    width: 400px;
                    gap: 0.5rem;

                    @extend %label-input;
                    
                    .input {
                        align-self: flex-end;
                    }

                    .branch-input {
                        background-color: #FFEAED;
                    }
                }
            }
        }

        .sub--section-3 {
            width: 90%;
            margin: 0 auto;

            @extend %label-input

           
        }

        .button-last {
            width: 90%;
            margin: 2rem auto;
            display: flex;

            button {
                background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 6px 16px #B522684D;
                border-radius: 5px;
                font: normal normal bold 16px/18px Ubuntu;
                letter-spacing: 0px;
                color: #FFFFFF;
                width: 180px;
                height: 46px;
                margin-top: 3rem;
                margin-left: auto;
                // margin-bottom: 25px;
            }
        }

        .button-last {
            width: 90%;
            margin: 25px auto;
            display: flex;
            // float:right;

            button {
                background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 6px 16px #B522684D;
                border-radius: 5px;
                font: normal normal bold 16px/18px Ubuntu;
                letter-spacing: 0px;
                color: #FFFFFF;
                width: 180px;
                height: 46px;
                margin-top: 1rem;
                margin-left: auto;
            }
        }

        .sub-main {
            border-bottom: 1px solid #dcdacb;
            box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);
            width: 90%;
            margin: 0 auto;
    
            &--1 {
                padding: 1rem 1rem;
                background: #B2B9B9 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                justify-content: center;
                gap: 7rem;
    
                p {
                    font: normal normal normal 16px/18px Ubuntu;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    padding: 0 1rem;
                }
    
                input {
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 1px solid #707070;
                    border-radius: 3px;
                    height: 30px;
                    width: 100%;
                    max-width: 250px;
                }
            }
    
            &--2 {
                .multiplier-input {
                    padding: 1.2rem 2rem;
                    
                    input {
                        height: 46px;
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        border: 1px solid #707070;
                        border-radius: 6px;
                        margin: 0 1rem;
                        width: 30%;
                        max-width: 150px;
                    }
    
                    span {
                        font-size: 1.5rem;
                    }
    
                }
            }
    
            
        }

        .button-last--1 {
            width: 90%;
            margin: 0 auto;
    
            
            .button-container {
                margin-left: auto;
                gap: 3rem;
                margin-top: 2rem;
    
                button:first-child {
                    background: transparent;
                    border: 2px solid #D0021B;
                    color: #D0021B;
                }
            }
        }
        
        
    }
}