.branch--definition {
    

    .headerdiv{
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
        padding:15px;
        border-radius: 5px;
        color: white;
    
    p{
       font-size: 18px;
       font-weight: bold;
    }
    } 
   select{
       min-width: 100%;
       height:44px;
       margin-left: 10px;
       border-radius: 5px;
       margin:4px 0;

   }
   label{
       margin-left: 0px;
       color:black;
       margin-bottom: 0;
   }
   input{
    min-width: 100%;
    height:44px;
    margin-left: 10px;
    border-radius: 5px; 
    margin:4px 0, 
   }
   .work-time, .status{
    background-color: rgb(230, 201, 206);
    width:100%;
    padding:20px;
    border-radius: 5px;
   }
   .savebtn{
         margin:50px auto;
         justify-content: center;
         
         .save{
            width: 160px;
            height:44px;
             background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
             color:white;
             border-radius: 5px;
         }
         .cancel{
            width: 160px;
            height:44px;
             margin-left: 19px;
             border:2px solid  #D0021B; ;
             color: #D0021B;;
             border-radius: 5px;
             background-color: white;

         }
   }
   .sub--section-z {
    margin-bottom: 1rem;
}

.main-filter-z {
  margin: 0rem 0 0rem auto;
  
  input  {
      margin: 0rem 0 0rem auto;
      border-radius: 5px;
      width: 30%;
      min-width: 20px;
      height: 44px;
      padding:  0 0 0 1rem;
  }

  button {
      background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
      border-radius: 5px;
      font: normal normal bold 16px/18px Ubuntu;
      letter-spacing: 0px;
      color: #FFFFFF;
      width: 160px;
      height: 44px;
      margin: 0 2rem;

      &:nth-child(3) {
          background: transparent;
          font: normal normal bold 16px/18px Ubuntu;
          color: #D0021B;
          border: 2px solid #D0021B;
          width: 200px;
          margin-right: 0;
      }

  }
}
}