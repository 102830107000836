.manage--permission {
    .headerdiv {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        padding: 15px;
        border-radius: 5px;
        color: white;

        p {
            font-size: 18px;
            font-weight: bold;
        }
    }

    .section--1 {
        .currency1 {
            height: 44px;
            border-radius: 5px;
            margin-bottom: 30px;
        }

        .spandiv {
            display: flex;
            justify-content: space-around;
            align-items: center;

            .checkboxdiv{
                width: 100%;
                display: flex;
                justify-content: center;
            }
            .spanbox {
                margin-left: 15px;
                margin-top: 5px;
            }
        }


        .sub--section {
            grid-gap: 1rem;
            grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

            label {
                letter-spacing: 0px;
                color: #262C2C;
                margin-bottom: 4px;
            }

            input, select {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                border-radius: 5px;
                min-width: 300px;
                height: 46px;
                padding-bottom: 30px;
            }

            .span {
                height: 20px;
                margin-top: 30px;
                position: relative;
                top: 6px;
            }



        }


        .button {
            width: 100%;
            text-align: center;
            margin-top: 3rem;

            button {
                background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 6px 16px #B522684D;
                border-radius: 5px;
                font: normal normal bold 16px/18px Ubuntu;
                letter-spacing: 0px;
                color: #FFFFFF;
                width: 160px;
                height: 44px;
            }
        }
    }

    .section--2 {
        padding: 2rem 0 0 0;

        h1 {
            font: normal normal bold 30px/28px Ubuntu;
            color: #262C2C;
            padding: 1rem 0
        }

        .sub--section {
            position: relative;
            right: 0;
        }

        .main-filter {
            margin: 1rem 0 2rem auto;

            input {
                border: 1px solid #B52268;
                border-radius: 5px;
                width: 300px;
                height: 44px;
                padding: 0 0 0 1rem;
            }

            button {
                background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
                border-radius: 5px;
                font: normal normal bold 16px/18px Ubuntu;
                letter-spacing: 0px;
                color: #FFFFFF;
                width: 160px;
                height: 44px;
                margin: 0 2rem;

                &:nth-child(3) {
                    background: transparent;
                    font: normal normal bold 16px/18px Ubuntu;
                    color: #D0021B;
                    border: 2px solid #D0021B;
                    width: 200px;
                    margin-right: 0;
                }

            }
        }

        table {
            width: 100%;
            font: 14px;
            white-space: nowrap;
        }

        td,
        th {
            text-align: center;
            padding: 8px;
            border: none;
        }

        // tr:nth-child(even) {
        //     background-color: #8f8c8c;
        // }

        .table--icon {
            margin-left: 1rem;

            &--2 {
                margin-left: .5rem;
            }
        }
    }
}