.general--ledger {
  background-color: #FFFFFF;

  // .export-button {
  //   width: 90%;
  //   margin: 15px;
  //   display: flex;
  //   justify-content: flex-end;
  //   font-weight: 500;

  //   .export--button {
  //     padding: 0.5rem;
  //     color: #d0021b;
  //     background-color: transparent;
  //     border: 2px solid #d0021b;
  //     border-radius: 5px;
  //     text-decoration: none;
  //   }
  // }
  

  .profit {
    border-bottom: 1px solid #dcdacb;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 4px 12px rgba(0, 0, 0, .08);
    padding: 3rem 2rem;
    // span {
    //   margin-right: 6px;
    // }
    // p {
    //   margin-left: 20px;
    // }
    input {
      height: 44px;
      min-width: 120px;
      max-width: 250px;
      border-radius: 5px;
    }
    // select {
    //   background: #ffffff 0% 0% no-repeat padding-box;
    //   border: 1px solid #707070;
    //   border-radius: 5px;
    //   width: 100%;
    //   max-width: 200px;
    //   // min-width: 250px;
    //   height: 44px;
    //   color: rgb(65, 64, 64);
    //   padding-left: 1rem;
    //   // margin: 4px 0;
    // }
    .sub__range {
        // // border: solid 1px red;
        // display: flex;
        // align-items: flex-end;
        // justify-content: space-between;
        // width: 100%;

        // .sub__range__1{
        // //   border: solid 1px red;
        //   display: flex;
        // }
        .sub__range__2{
          display: flex;
          align-items: center;
        }

        label{
            width: 50%
        }
        // input{
        //     margin-left: 0.5rem;
        // }

        // .date__from {
        //   //   border: solid 1px rgb(99, 14, 209);
        //     margin-left: 1rem;
        //     // display: flex;
        //   }

        // .date__to {
        // //   border: solid 1px rgb(99, 14, 209);
        //   margin-left: 1rem;
        //   // display: flex;
        // }

        .report__type {
        //   border: solid 1px rgb(99, 14, 209);
          margin-left: 4rem;
          // display: flex;
        }
      }
    .income-div {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      margin: 20px;

      // span {
      //   display: flex;
      //   align-items: center;
      // }
      p {
        font-size: 30px;
      }
      .income-p {
        margin-left: -12px;
      }
    }
    .last-btn {
      display: flex;
      
      button {
        background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 6px 16px #B522684D;
        border-radius: 5px;
        font: normal normal bold 16px/18px Ubuntu;
        letter-spacing: 0px;
        color: #FFFFFF;
        width: 100px;
        height: 46px;
        margin-top: 2rem;
        margin-bottom: .4rem;
        margin-right: 1rem;
        
      }
    }
  }
  .profit__2 {
    margin-top: 3rem;
    background-color: #aaaaaa;
  }

  // .total_trialBalance{
  //   display: flex;

    // .total__1{
    //   // display: flex;
    //   // input {
    //   //   background: #ffffff 0% 0% no-repeat padding-box;
    //   //   border: 1px solid #707070;
    //   //   border-radius: 5px;
    //   //   width: 100%;
    //   //   max-width: 200px;
    //   //   // min-width: 250px;
    //   //   height: 44px;
    //   //   color: rgb(65, 64, 64);
    //   //   padding-left: 1rem;
    //   //   // margin: 4px 0;
    //   // }
    // }
  // }
}
//     .section--1 {
//         padding-bottom: 5rem;
//         border-bottom: 1px solid #dcdacb;
//         box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);
//     .bsrow{
//         padding: 5rem;
//         .bs--sn{
//             margin-right: 20px;
//         }
//         input {
//             background: #FFFFFF 0% 0% no-repeat padding-box;
//             border: 1px solid #707070;
//             border-radius: 5px;
//             width: 100%;
//             max-width: 300px;
//             // min-width: 250px;
//             height: 44px;
//             color: rgb(65, 64, 64);
//             padding-left: 1rem;
//             // margin: 4px 0;

//         }
//         .dowload--btn{
//             background: transparent;
//             font: normal normal bold 16px/18px Ubuntu;
//             color: #D0021B;
//             border: 2px solid #D0021B;
//             width: 130px;
//             height: 44px;
//             margin-left: 50px;
//             border-radius: 5px;

//         }
//         .view--btn{
//             background: #FFEAED;
//             font: normal normal bold 16px/18px Ubuntu;
//             color: #D0021B;
//             border: 2px solid #FFEAED;
//             width: 90px;
//             height: 44px;
//             margin-left: 50px;
//             border-radius: 5px;
//             box-shadow:2px 2px 2px 2px rgb(223, 218, 218)

//         }
//     }
// }
//         .part-2{
//         .table-head{
//             display: flex;
//             justify-content: space-between;
//         }
//         .sub-div-0{
//             margin: 10px;
//             font-weight: bold;
//         }

//         .sub-div-1{
//             padding: 20px;
//             margin: 10px 30px;
//         }
//         .back-ash{
//             background-color: #EAEAEA;
//         }
//         .red-text{
//             color: red;
//             font-weight: bold;
//         }
//         .total {
//             font-weight: bold;
//         }
//         .arrow-flex{
//             display: flex;
//             cursor: pointer;
//         }
//     }
//          .arrowback{
//              padding-top: 13px;
//              padding-left: 10px;
//          }
