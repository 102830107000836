.till-management {
    
    .heading {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
        padding:15px;
        border-radius: 5px;
    }
    .heading p{
        color: white;
        font-size: 18px;
        font-weight: bold;
    }

    .section--1 {
        padding: 2rem;
    }

    .img-description {
        padding-bottom: 2rem;
        gap: 2rem;

        p {
            width: 60%
        }
    }
    
}