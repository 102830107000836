.balance--sheet{
  background-color: #FFFFFF;

  // .export-button {
  //   width: 90%;
  //   margin: 15px;
  //   display: flex;
  //   justify-content: flex-end;
  //   font-weight: 500;

  //   .export--button {
  //     padding: 0.5rem;
  //     color: #d0021b;
  //     background-color: transparent;
  //     border: 2px solid #d0021b;
  //     border-radius: 5px;
  //     text-decoration: none;
  //   }
  // }
  // .formdiv {
  //   align-items: center;
  //   margin-top: 1rem;
  // }
  
  
  // .main input {
  //   padding: 0.8rem;
  //   border: 1px solid #aaa3a3;
  //   width: 7.5rem;
  //   border-radius: 5px;
  
  // }
  // .MuiSelect-outlined.MuiSelect-outlined {
  //   border: 1px solid #aaa3a3;
  //   width: 6.5rem;
  //   padding: 0.8rem;
  // }
  
  // .summary-div {
  //   .sub-div-0 {
  //     margin-bottom: 35px;
  //     margin-top: 2rem;
  //   }
  //   .tabs {
  //     width: "100%";
  //   }
  //   table {
  //     font-family: arial, sans-serif;
  //     border-collapse: collapse;
  //     width: 100%;
  //   }
  
  //   td,
  //   th {
  //     border: 1px solid #dddddd;
  //     text-align: left;
  //     padding: 8px;
  //   }
  //   .tab {
  //     border: "4px solid green";
  //   }
  // }

  .profit {
    border-bottom: 1px solid #dcdacb;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 4px 12px rgba(0, 0, 0, .08);
    padding: 3rem 2rem;
    // .date-range {
    //   background-color: #ffeaed;
    //   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
    //   padding: 1.5rem;
    // }
    // span {
    //   margin-right: 6px;
    // }
    p {
      margin-left: 20px;
    }
    input {
      height: 44px;
      min-width: 120px;
      max-width: 250px;
      border-radius: 5px;
    }
    // select {
    //   background: #ffffff 0% 0% no-repeat padding-box;
    //   border: 1px solid #707070;
    //   border-radius: 5px;
    //   width: 100%;
    //   max-width: 200px;
    //   // min-width: 250px;
    //   height: 44px;
    //   color: rgb(65, 64, 64);
    //   padding-left: 0.6rem;
    //   // margin: 4px 0;
    // }
      .sub__range {
        // border: solid 1px red;
        // display: flex;
        // align-items: flex-end;
        // justify-content: space-between;
        // width: 100%;

      
        .sub__range__bs2{
          display: flex;
          // justify-content: space-between;
          align-items: center;

          // align-items: flex-end;
        }

        label{
            width: 50%
        }
       

        // .date-to {
        // //   border: solid 1px rgb(99, 14, 209);
        //   margin-left: 1rem;
        //   // display: flex;
        // }

        .report__type {
        //   border: solid 1px rgb(99, 14, 209);
          margin-left: 4rem;
          // display: flex;
        }


      }
    .income-div {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      margin: 20px;

      // span {
      //   display: flex;
      //   align-items: center;
      // }
      p {
        font-size: 30px;
      }
      .income-p {
        margin-left: -12px;
      }
    }
    .last-btn {
      display: flex;
      
      button {
        background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 6px 16px #B522684D;
        border-radius: 5px;
        font: normal normal bold 16px/18px Ubuntu;
        letter-spacing: 0px;
        color: #FFFFFF;
        width: 100px;
        height: 46px;
        margin-top: 2rem;
        margin-bottom: .4rem;
        margin-right: 1rem;
        
      }
    }
  }

}
