.bulk-upload--authorization{
    background-color: #ffffff;

    %label-input {
        label {
            margin-bottom: 3px;
            letter-spacing: 0px;
            color: #262C2C;
        }

        input, select {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #707070;
            border-radius: 5px;
            width: 30%;
            max-width: 300px;
            min-width: 150px;
            height: 46px;
            color: rgb(65, 64, 64);
            padding-left: 1rem;
            margin-bottom: 1rem;

        }
    }

    .section--1{
        padding: 3rem 2rem;
        border-bottom: 1px solid #dcdacb;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 4px 12px rgba(0, 0, 0, .08);
        .sub-main{
            // margin-bottom: 1rem;
            .sub-main--1{
                margin-right: 3rem;
                input {
                    height: 44px;
                    min-width: 120px;
                    max-width: 250px;
                    border-radius: 5px;
                }
            }
        }

        .last{
            .last--input{
                .last--one{
                    @extend %label-input;
                }
            }
        }
    }
}