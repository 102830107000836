.loan--amendment{
    
    .headerdiv{
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
        padding:15px;
        border-radius: 5px;
        color: white;
    
   p{
    font-size: 18px;
    font-weight: bold;
   } 
}
   select{
       width: 100%;
       height:44px;
       margin-left: 10px;
       border-radius: 5px;
       margin:4px 0;
   }
   label{
       margin-left: 0px;
       margin-bottom: 0;
       white-space: nowrap;
   }
   input[type="date"]{
    height:44px;
    border-radius: 5px; 
    margin:4px 0; 
   }
   input[type="text"]{
    // width: 100%;
    height:44px;
    border-radius: 5px; 
    margin:4px 0; 
   }
   input[type="number"]{
    width: 100%;
    height:44px;
    margin-left: 10px;
    border-radius: 5px; 
    margin:4px 0; 
   }
   input[type='checkbox']{
       margin-top: 2px;
   }

  
   .span{
       padding-top: 0;
       padding-left: 20px;
   }
   .btnz{
       background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;  
       color:white;
       position:relative;
       width:150px;
       height:44px;
       border-radius: 7px;
       margin: 3rem 0 2rem;
   }
   .checkbtn{
       background-color: #D0021B;
       color:white;
       position:relative;
       width:230px;
       height:44px;
       border-radius: 7px;
       margin-top: 30px;
       left:50%;
     
   }
   .checkers {
        display: flex;
        align-items: center;
    }
    .btn--1{
        background: transparent;
        color: #D0021B;
        border: 2px solid #D0021B;
        position:relative;
        width: 160px;
        height: 44px;
        border-radius: 7px;
    }

    .tenor {
        min-width: 100px;
    }

    .button--cont {
        margin: 3rem 0 2rem;
        justify-content: flex-end;
    }

    .btn--2{
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;  
        color:white;
        position:relative;
        width: 170px;
        height:44px;
        border-radius: 7px;
    }

    .section--1 {
        margin: 1rem 0 0;
        grid-gap: 1.5rem 1rem;

        .flex--3 {
            flex: 1 1;
            max-width: 250px;
        }
    }
    .section--2 {
        width: 170px;
        margin: 1.5rem 0;
    }

    .main--2 {
        padding: 1.5rem;
        .flex--3 {
            flex: 1 1 150px;
            max-width: 250px;
        }
    }
}


