.individualborrower {
    background-color: #FFFFFF;

    .button--1{
        button{
            background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
            box-shadow: 0px 6px 16px #B522684D;
            border-radius: 5px;
            font: normal normal bold 16px/18px Ubuntu;
            letter-spacing: 0px;
            color: #FFFFFF;
            width: 100px;
            height: 46px;
            margin-top: 1rem;
            margin-bottom: 2rem;
            margin-right: 1rem;
            
        }
    }

    .button--2{
        button{
            background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
            box-shadow: 0px 6px 16px #B522684D;
            border-radius: 5px;
            font: normal normal bold 16px/18px Ubuntu;
            letter-spacing: 0px;
            color: #FFFFFF;
            width: 200px;
            height: 46px;
            margin-top: 1rem;
            margin-bottom: 2rem;
            margin-right: 1rem;
            
        }
    }
    .section--1{
        padding: 3rem 2rem;
        border-bottom: 1px solid #dcdacb;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 4px 12px rgba(0, 0, 0, .08);

        .sub-main{
            margin-bottom: 1rem;
            .sub-main--1{
                margin-right: 3rem;
                input {
                    height: 44px;
                    min-width: 120px;
                    max-width: 250px;
                    border-radius: 5px;
                }
                select {
                    height: 44px;
                    min-width: 120px;
                    max-width: 250px;
                    border-radius: 5px;
                }
            }
        }

    }

    .not--loaded {
        grid-gap: .5rem;
        margin: 15rem 0;
        
        .circle-error {
            font-size: 36px;
            color: #ac0116;
            // margin: 1rem 0;
        }

        h4 {
            margin: 0;
            font-size: 18px;
        }
    }
}