.Product--definition {
  .headerdiv {
    background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
    padding: 15px;
    border-radius: 5px;
    color: white;

    p {
      font-weight: bold;
      font-size: 18px
    }
  }

  .cancel--icon {
    background: #ffeaed 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 5px;
    width: 80px;
    height: 44px;
    color: black;
    border: none;
    align-self: flex-end;
    
  }
  
  .product--def {
    grid-gap: 1.5rem 1rem;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;


    .flex--3 {
      flex: 1 1 150px;
    }

    .input--1 {
      max-width: 150px;
    }

    .input--2 {
      min-width: 200px;
      max-width: 500px
    }

    .input--3 {
      max-width: 300px;
    }

    .input--4 {
      max-width: 200px;
    }

    .input--5 {
      max-width: 150px;
    }

    input {
      min-width: 10px;
    }
  }

  label {
    margin-left: 0px;
    margin: 0;
  }

  input {
    height: 44px;
    margin-left: 10px;
    border-radius: 5px;
    margin: 1px 0;
    position: relative;
  }

  .checboxdiv {
    grid-gap: 10px;

    input,
    label {
      margin: 0;
      padding: 0;
    }

  }

  select {
    height: 44px;
    margin-left: 10px;
    border-radius: 5px;
    margin: 1px 0;
    padding-left: 1rem;
    position: relative;
  }

  .span {
    position: relative;
    top: -3px
  }

  .btn-next {
    justify-content: flex-end;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;

    .next {
      background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
      border-radius: 5px;
      color: #FFFFFF;
      width: 160px;
      height: 44px;
    }
  }

  .sub--section {
    position: relative;
    right: 0;


    .main-filter {
      margin: 1rem 0 2rem auto;

      .filter {
        border: 1px solid #B52268;
        border-radius: 5px;
        width: 30px;
        min-width: 150px;
        height: 44px;
        padding: 0 0 0 1rem;
      }

      button {
        background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        border-radius: 5px;
        font: normal normal bold 16px/18px Ubuntu;
        letter-spacing: 0px;
        color: #FFFFFF;
        width: 160px;
        height: 44px;
        margin: 0 2rem;

        &:nth-child(3) {
          background: transparent;
          font: normal normal bold 16px/18px Ubuntu;
          color: #D0021B;
          border: 2px solid #D0021B;
          width: 200px;
          margin-right: 0;
        }

      }
    }
  }

  .divspan {
    margin-top: 40px;

    .span2 {
      font-weight: bold;
      font-size: 25px;
    }
  }
  
  .section--2 {
    .clearingbox {
      border: 2px solid rgb(17, 17, 17);
      padding: 1rem;
      border-radius: 4px;
    }
  
    .button-flex {
      align-items: center;
      width: 80px;
      margin-top: 1rem;
    }
  
    .add {
      border-radius: 50px;
      width: 37px;
      height: 37px;
      background-color: white;
      color: #d0021b;
      box-shadow: 2px 2px 2px 2px #ebacb3;
      margin-bottom: 8px;
    }
  
    .button--2 {
      width: 90%;
      margin-top: 50px;
      margin-bottom: 50px;
      justify-content: flex-end;
  
      .save {
        background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        border-radius: 5px;
        color: #FFFFFF;
        width: 160px;
        height: 44px;
      }
  
      .previous {
        background: transparent;
        color: #D0021B;
        border: 2px solid #D0021B;
        width: 160px;
        height: 44px;
        border-radius: 5px;
        margin-right: 30px;
      }
    }
  }

  #button--cont {
    padding: 2rem 0 1rem;
  }

  .view-container .view-body {
    grid-gap: 1rem 26px;
    padding-bottom: 1rem;
    padding-top: 1rem;
    margin-top: 2px;
  }
}
