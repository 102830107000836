.chequedeposit{
    color: white;
    
    .headerdiv{
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
        padding:7px;
        border-radius: 5px;
        height:50px;
    }
    p{
        padding-top: 10px;
        padding-left: 20px;
    }  
    label{
       margin-left: 0px;
   }
   input{
    // min-width: 100%;
    height:44px;
    margin-left: 10px;
    border-radius: 5px; 
    margin:10px 0; 
    width: 100%;
    max-width: 400px;
   }

   .savebtn{
    width:20%;
      margin:50px auto;
      .save{
          width:45%;
          height:35px;
          background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
          color:white;
          border-radius: 5px;
      }
      .cancel{
          width:45%;
          height:35px;
          margin-left: 19px;
          border:2px solid  #D0021B;;
          color: #D0021B;;
          border-radius: 5px;
          background-color: white;

      }
}
}