
.sidebar {
    width: 23%; 
    margin-left: 0;
    height: 100vh;
    position: fixed;
    background-color: rgb(255, 255, 255);
    backdrop-filter: grayscale(0.5) blur(100px);
    box-shadow: 0 1px 10px rgb(232, 230, 230);
    overflow-y: scroll;
    padding-top: 7rem;
    z-index: 1;
    transition: all .2s ease-in;

    ul {
        margin-bottom: 0;
    }


    a {
        text-decoration: none;
    }
    

    // * {
    //     scrollbar-width: thin;
    //     scrollbar-color: grey;
    //   }
      
      /* Works on Chrome/Edge/Safari */
      *::-webkit-scrollbar {
      width: 5px;
      }
      
      
      *::-webkit-scrollbar-thumb {
      background-color: rgba(177, 177, 177, 0.412);
      border-radius: 20px;
      }



    .main--2 {
        flex: 1;
        width: 100%; 


        img {
            fill: red;
        }

        %active {
            position: relative;

            p {
                color: #D71920;
                font-weight: 700;
            }

            .bsfill {
                fill: #D71920;
            }

            svg {
                .a {
                    fill: #D71920;
                } 
            }
        }
        
        
        .icon-p {
            margin-bottom: 0rem;
            transition: all .2s ease-in;

            label {
                &:hover {
                    background-color: #FFEAED;
                }
            }


            .each-link {
                text-decoration: none;
                padding: .7rem 0 .7rem 2rem;
                width: 100%;
                color: inherit;
                cursor: pointer;

                

                p {
                    white-space: nowrap;
                }

                .bsfill {
                    margin-top: .5rem;
                }
                
                &:hover {
                    
                    .bsfill {
                        fill: #D71920;
                    }
                 
                    p {
                        color: #D71920;
                    }

                    
                }
            }

            

            svg {
                font-size: 1rem;
                width: 19.489px;
                height: 15.158px; 
 
                .a {
                    fill:#858585;
                }
            }
            
            a.active {
                @extend %active
            }
            

            .bsfill {
                color: #707070;
            }

            p {
                margin-left: 1.5rem;
                color: #707070;
            }

            .side-icon {
                width: 1rem;
            }
            
        }

        .sub-link {

            label {
                margin-bottom: 0;
            }
            
            .sub-link {
                margin: .5rem 0 1rem 2.5rem;
            }

            .icon-p {
                margin-bottom: 0rem;
                transition: all .1s ease-in;

                h2 {
                    position: relative;
                    top: 3px;
                    transition: all .1s ease-in;
                    height: 10px;
                    transform: translateY(-15px);

                    &:hover {
                        color: #D71920;
                    }
                }
                
                
                p {
                    margin-left: 10px;
                    color: #707070;
                }
    
                label {
                    margin-left: -6px;
                    &:hover {
                        background-color: transparent;
                        color: #D71920;
                    }
                }
            }

            [id^=btn]:checked + .sub-link { 
                height: 100%;
                margin: 0 0 1rem 2.5rem;
                visibility: visible;
            }
        }

        input {
            display: none;
        }
        

        .sub-link {
            transition: all .1s ease-in;   
            visibility: hidden;
            // display: none;
            height: 0;
            margin-left: 4rem;
            // border: 2px solid red; 

            .each-link {
                padding: 0;
            }

            ul { 
                li {
                    padding: .5rem 0;
                    cursor: pointer;
                    font: normal normal normal 14px/18px Ubuntu;
                    letter-spacing: 0px;
                }
            }

            a.active {
                li {
                    color: #D71920;
                    font-weight: bold;
                }

            }
            
        }

        [id^=btn]:checked + .sub-link { 
            height: 100%;
            margin: .5rem 0 1rem 4rem;
            visibility: visible;
        }
        
    }

    .hide--options {
        display: none;
    }
    
    .show--options {
        height: 100%;
        display: inline-block;
        height: 100%;
        margin: .5rem 0 1rem 4rem;
        visibility: visible;
        border: 2px solid red;
    }


}

.hide-sidebar {
    margin-left: -23vw;
}



@media screen and (max-width: 900px) {
    .sidebar .main--2 .icon-p p {
        margin-left: 0rem;
        color: #707070;
        font-size: 12px;
    }

    .sidebar .main--2 .icon-p .each-link {
        padding: .7rem 0 .7rem 1rem;
    }

    .sidebar .main--2 .icon-p .each-link {
        flex-direction: column;
    }

    .sidebar .main--2 .icon-p .each-link p {
        white-space: pre-wrap;
        text-align: center;
        padding-left: 0;
    }

    .sidebar .main--2 .sub-link {
        margin-left: 0;
    }

    .sidebar .main--2 [id^=btn]:checked + .sub-link {
        height: 100%;
        margin: .5rem 0 1rem 0rem;
    }

    .sidebar .main--2 .sub-link ul li {
        font: normal normal normal 11px/18px Ubuntu;
    }

}
@media screen and (min-width: 900px) {
    .sidebar {
        margin-left: 0vw;
    }
    
}