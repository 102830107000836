.customer--credit {

    .headerdiv {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        padding: 15px;
        border-radius: 5px;
        color: white;

        p {
            font-weight: bold;
            font-size: 18px
        }
    }
    
    .flex--3 {
        flex: 1 1;
        min-width: 150px;
        max-width: 600px;
    }

    select {
        width: 100%;
        height: 44px;
        margin-left: 10px;
        border-radius: 5px;
        margin: 4px 0;
    }

    label {
        margin-left: 0px;
        margin-bottom: 0px;
    }

    input {
        width: 100%;
        height: 44px;
        margin-left: 10px;
        border-radius: 5px;
        margin: 4px 0;
    }

    .input-container--1 {
        grid-gap: 1rem;
        padding-bottom: 1rem;
        flex-wrap: wrap;
    }

    .btn {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        color: white;
        position: relative;
        width: 180px;
        height: 44px;
        border-radius: 7px;
        margin-top: 80px;
        left: 70%;
        margin-bottom: 30px;
    }

    .pink {
        background-color: rgb(250, 237, 239);
    }

    .currency {
        max-width: 160px;
    }

    .error--container {
        position: relative;

        small {
            position: absolute;
            left: 0;
            bottom: -15px;
            color: red;
        }
    }

}