.gl--transfers{
    .section--1{
        padding-bottom: 5rem; 
        border-bottom: 1px solid #dcdacb;
        box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);
         small{
             color: red;
         }
        .heading {
            background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
            padding:15px;
            border-radius: 5px;
            color: white;
        
        p {
            font-size: 18px;
            font-weight: bold;
        }
        }

        %label-input {
            label {
                letter-spacing: 0px;
                color: #262C2C;
               margin-bottom: 4px;
            }
            
            input {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                border-radius: 5px;
                width: 100%;
                // max-width: 400px;
                // min-width: 250px;
                height: 46px;
                color: rgb(65, 64, 64);
                padding-left: 1rem;

            }
            select {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                border-radius: 5px;
                width: 100%;
                // max-width: 400px;
                // min-width: 250px;
                height: 46px;
                color: rgb(65, 64, 64);
                padding-left: 1rem;
            }
        }

        
        .sub--section{
            // justify-content: space-between;
            width: 90%;
            margin: 0 auto;

            .sub--main{
                margin-right: 10px;
                // margin-bottom: 5rem;
                @extend%label-input;

                .input{
                    // 
                    align-items: flex-end;
                    grid-gap: 0.5rem;
                    margin-bottom: 1rem;
                    // border: 2px solid cyan;

                    .label--input{

                        label{
                            white-space: nowrap;
                        }
                        .debit--GL--input{
                                border: solid 1px pink;
                                background: #FFEAED 0% 0% no-repeat padding-box;
                                border: 1px solid #707070;
                            }
                    }
                }

                .currency--credit{
                    // border: 1px solid red;
                    margin-top: 0.5rem;
                    .label--input-currency{
                        margin-top: 1rem;
                    }
                    margin-top: 1rem;
                    .credit{
                        align-items: flex-end;
                        grid-gap: 1rem;
    
                        .label--input{
    
                            label{
                                white-space: nowrap;
                            }
                            .credit--GL--input{
                                    border: solid 1px pink;
                                    background: #FFEAED 0% 0% no-repeat padding-box;
                                    border: 1px solid #707070;
                                }
                        }
                    }
                }

                

                .debit--GL--input{
                    // border: 1px solid blue;
                }

                &--1 {
                    @extend%label-input;
                    .input{
                        // align-items: center;
                        align-items: flex-end;
                        grid-gap: 1rem;
                        margin-bottom: 1rem;
                        // border: 2px solid cyan;

                        .label--input{
                            .debit--GL--input{
                                border: solid 1px pink;
                                background: #FFEAED 0% 0% no-repeat padding-box;
                                border: 1px solid #707070;
                            }
                        }
                    }

                    .label--input--amount{
                        margin-top: 6.6rem;
                        .input{
                            .flex--3{
                                // 
                                .debit--GL--input{
                                    border: solid 1px pink;
                                    background: #FFEAED 0% 0% no-repeat padding-box;
                                    border: 1px solid #707070;
                                }
                            }
                        }
                    }
                    
    
                    .debit--GL--input{
                        // border: 1px solid blue;
                    }
                }
            }



            .button-last {
                width: 90%;
                margin: 0 auto;
                display: flex;
                // margin-bottom:3rem;
    
                button {
                    background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
                    box-shadow: 0px 6px 16px #B522684D;
                    border-radius: 5px;
                    font: normal normal bold 16px/18px Ubuntu;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    width: 180px;
                    height: 46px;
                    margin-top: 2rem;
                    margin-left: auto;
                }
            }

        }

        .tobi{
            margin: 0 auto;
            width:90%;

            .label--input-narration{
                @extend%label-input;
                margin-top: 1rem;
            }
            small{
                position: absolute;
                color:red;  
            }

        }
        .button-last {
            width: 90%;
            justify-content: flex-end;
            margin: 0 auto;
            

            button {
                background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 6px 16px #B522684D;
                border-radius: 5px;
                font: normal normal bold 16px/18px Ubuntu;
                letter-spacing: 0px;
                color: #FFFFFF;
                width: 180px;
                height: 44px;
                margin-top: 2.5rem;
            }
        }   
        
        .max--error {
            width: 105px;
        }
            
    }
}