.rate-Charts {
    .heading {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        padding: 15px;
        border-radius: 5px;
        color: white;

        p {
            font-size: 18px;
            font-weight: bold;
        }
    }

    .main-section--1 {
        padding: 2rem 2rem 2rem;

    }

    .section--1 {
        border-bottom: 1px solid grey;
        padding-bottom: 5rem;

        .currency{
            height:44px;
            border-radius: 5px;
        }
    
        .sub--section {
            grid-gap: 1rem;
            grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    
            label {
                font: normal normal medium 18px/21px Ubuntu;
                letter-spacing: 0px;
                color: #262C2C;
                padding-bottom: 5px;
                margin-bottom: 0; 
            }
    
            input, select {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                border-radius: 5px;
                min-width: 300px;
                height: 44px;
            }
        }
    
        .button {
            width: 100%;
            text-align: center;
            margin-top: 3rem;
    
            button {
                background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 6px 16px #B522684D;
                border-radius: 5px;
                font: normal normal bold 16px/18px Ubuntu;
                letter-spacing: 0px;
                color: #FFFFFF;
                width: 160px;
                height: 44px;
            }
        }
    }
    
    .section--2 {
        padding: 0 2rem 2rem;
    }

    .section-container {
        padding: 0 2rem 2rem;

        .sub-heading {
            font: normal normal bold 24px/28px Ubuntu;
            letter-spacing: 0px;
            color: #2A2A2A;
            padding: 1rem 0 1rem;
        }
    }

    .main-section--2 {
        width: 100%;
        gap: 1.5rem;
        padding: 0 0 1rem;

        span {
            margin: 3.2rem 0 0;
        }

        .span--1 {
            margin: .5rem 0 0;
        }
    }

    .main-container {
        justify-content: space-between;
        gap: 2rem;
        padding: 0 0 2rem;

        %p {
            font: normal normal medium 20px/21px Ubuntu;
            font-size: 18px;
            letter-spacing: 0px;
            color: #262C2C;
            opacity: 1;
            padding: 0 0 4px 0;
        }

        &--1 {
            flex: 1 1 30%;

            p {
                @extend %p;
            }

            input {
                height: 44px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                border-radius: 5px;
                opacity: 1;
                width: 100%;
            }
        }

        &--2 {
            flex: 1 1 70%;

            p {
                @extend %p;
            }

            input {
                height: 44px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                border-radius: 5px;
                opacity: 1;
                width: 100%;
            }
        }
    }

    .sub-container {
        gap: 3rem;
        white-space: nowrap;
        flex-wrap: wrap;

        p {
            font: normal normal medium 20px/21px Ubuntu;
            font-size: 18px;
            letter-spacing: 0px;
            color: #262C2C;
            opacity: 1;
            padding: 0 0 4px 0;
        }

        &--1,
        &--2,
        &--3 {
            width: 28%;
            min-width: 200px;
        }

        input,
        select {
            width: 100%;
            height: 44px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #707070;

            border-radius: 5px;
        }

        select {
            padding-left: 1rem
        }
    }

    .section {
        width: 250px;

        .input-container {
            width: 100%;

            p {
                font: normal normal medium 18px/21px Ubuntu;
                letter-spacing: 0px;
                color: #2A2A2A;
                padding: 0 0 1rem 0;
            }
        }

        input,
        select {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 0.800000011920929px solid #707070;
            border-radius: 5px;
            height: 46px;
            width: 100%;
        }

    }

    button {
        width: 100px;
        height: 44px;
        background: #FFEAED 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #00000029;
        border-radius: 5px;
    }

    .button-p {
        margin-top: 2rem;
        justify-content: space-between;

        .button {
            border-radius: 50%;
            padding: 0;
            width: 50px;
            font-size: 1.5rem;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 1px 5px #C2000A57;
            color: #C2000A;
        }



        p {
            font: normal normal 300 16px/21px Ubuntu;
            letter-spacing: 0px;
            color: #C2000A;
            padding: 1rem 0;
        }

        .submit {
            background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
            color: white
        }
    }

    .button--1 {
        align-self: flex-end;
    }

    .error--input {
        border: 2px solid red !important;

    }

    .error--input:focus {
        border: 2px solid red;
    }
    .sub--section-z {
        margin-bottom: 1rem;
    }
    
    .main-filter-z {
        margin: 0rem 0 0rem auto;
    
        input {
            margin: 0rem 0 0rem auto;
            border-radius: 5px;
            width: 50%;
            min-width: 20px;
            height: 44px;
            padding: 0 0 0 1rem;
        }
    
        button {
            background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
            border-radius: 5px;
            font: normal normal bold 16px/18px Ubuntu;
            letter-spacing: 0px;
            color: #FFFFFF;
            width: 160px;
            height: 44px;
            margin: 0 2rem;
    
            &:nth-child(3) {
                background: transparent;
                font: normal normal bold 16px/18px Ubuntu;
                color: #D0021B;
                border: 2px solid #D0021B;
                width: 200px;
                margin-right: 0;
            }
    
        }
    }
}
