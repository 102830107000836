.InvestmentCertificate {
    background-color: whitesmoke;

    .not--loaded {
        grid-gap: .5rem;
        margin: 15rem 0;
        
        .circle-error {
            font-size: 36px;
            color: #ac0116;
            // margin: 1rem 0;
        }

        h4 {
            margin: 0;
            font-size: 18px;
        }
    }
}