.account--info {
  select {
    // min-width: 100%;
    height: 44px;
    margin-left: 20px;
    border-radius: 5px;
    margin: 4px 0;
    min-width: 400px;
    padding-left: 10px; 
  }

  input {
    //  min-width: 100%;
    padding-left: 10px;
    height: 44px;
    margin-left: 10px;
    border-radius: 5px;
    margin: 4px 0;
    min-width: 400px;
  }

  .icon--error {
    transform: translateY(-3px);
  }
  label{
    margin: 0;
  }


  .product--def {
    grid-gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 7px; 
  
    .flex--3 {
      flex: 1 1 150px;
    }
    
    .input--1 {
      max-width: 150px;
    }
  
    .input--2 {
      min-width: 200px;
      max-width: 540px
    }
    .input--3{
      max-width: 300px;
    }
    .input--4{
      max-width: 420px;
    }
    .input--5{
      max-width: 135px;
    }
    input {
      min-width: 10px;
    }
    select{
        min-width: 10px;
    }
  } 
}