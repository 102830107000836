.chequeTransfer {
    .section--1 {
        padding-bottom: 5rem; 
        border-bottom: 1px solid #dcdacb;
        box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);

        .heading {
            background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
            padding: 15px 1.5rem;
            font-size: 18px;
            border-radius: 5px;
            letter-spacing: 0px;
            color: #FFFFFF;
            margin-bottom: 3rem;
        }

        %label-input {
            label {
                letter-spacing: 0px;
                color: #262C2C;
                margin-bottom: 3px;
            }

            input {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                border-radius: 5px;
                width: 100%;
                max-width: 500px;
                // min-width: 250px;
                height: 44px;
                color: rgb(65, 64, 64);
                padding-left: 1rem;

            }
        }



        .button-last {
            width: 90%;
            margin: 0 auto;
            display: flex;

            button {
                background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 6px 16px #B522684D;
                border-radius: 5px;
                font: normal normal bold 16px/18px Ubuntu;
                letter-spacing: 0px;
                color: #FFFFFF;
                width: 180px;
                height: 44px;
                margin-top: 2rem;
                margin-left: auto;
            }
        }

        .sub-section {
            @extend %label-input;
            
            
            .container-main {
                grid-gap: 0.5rem;
            }

            .input-container {
                width: 50%;
                grid-gap: 0.5rem;
                padding-bottom: 2rem;

                &--1 {
                   grid-gap: 0.5rem;

                    .sub-input {
                        align-self: flex-end;
                    }
                }

            }
        }

        .last-input {
            padding: 1rem 0;

            input {
                max-width: 100%;
            }
        }
    }
}