
.singledebitMultiplecredit{
    .product--def {
        grid-gap: 1rem;
        flex-wrap: wrap;
        margin-bottom: 7px;
      
        .flex--3 {
          flex: 1 1 150px;
        }
        
        .input--1 {
          max-width: 150px;
        }
      
        .input--2 {
          min-width: 200px;
          max-width: 500px
        }
        .input--3{
          max-width: 600px;
        }
        .input--4{
          max-width: 700px;
        }
        .input--5{
          max-width: 205px;
        }
        .input--5-1{
          max-width: 170px;
        }
        .input--6{
            max-width: 400px
        }
        input {
          min-width: 10px;
        }
        label {
            letter-spacing: 0px;
            color: #262C2C;
            margin-bottom: 0.2rem;
        }

        input {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #707070;
            border-radius: 5px;
            height: 44px;
            color: rgb(65, 64, 64);
            padding-left: 1rem;
            // margin: 4px 0;

        }
        .sub-input {
            align-self: flex-end;
        }
        .button-last {
            width: 90%;
            margin: 0 auto;
            display: flex;
            margin-bottom: 2rem;



            button {
                background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 6px 16px #B522684D;
                border-radius: 5px;
                font: normal normal bold 16px/18px Ubuntu;
                letter-spacing: 0px;
                color: #FFFFFF;
                width: 130px;
                height: 44px;
                margin-top: 2rem;
                margin-left: auto;
            }
        }
            .input-flex{
                left: 40%
            }
       
      }
      .down-flex{
        border-radius: 7px;
        font-size: 18px;
        padding: 20px 0px;
        border: 1px solid black;
        margin-top: 50px
    }

    .total-input{
        margin-top: 30px;
        background-color: #AAAAAA;
        padding: 10px;
        padding-left: 539px;
    }

    .delete{
        border: 1px solid black;
        display: flex;
        align-items: center;
        font-size: 18px;
        margin-top: 10px;
        cursor: pointer;
        background-color:#dcdacb;
        border-radius: 3px;
    }
    .span-total{
        margin-top: 7px;
        margin-right: 2px
    }
    .flex-p{
        margin-left: 60px;
    }
    .flex-f{
        margin-left: 15px;
    }




    .section--1{
        padding-bottom: 5rem; 
        border-bottom: 1px solid #dcdacb;
        box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);

        .heading {
            background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
            padding:15px;
            border-radius: 5px;
            color: white;
        
        p{
            font-size: 18px;
            font-weight: bold;
        }
    }

        .button--double {
            width: 90%;
            margin: 0 auto;
            justify-content: center;
            gap: 8rem;
            padding: 0 0 2rem 0;

            .flex--3 {
                align-items: center;
            }

            button {
                border-radius: 50%;
                padding: .5rem 1rem;
                border: 3px solid #D0021B;
                width: 50px;
                font: normal normal medium 24px/28px Ubuntu;
                font-size: 20px;
                letter-spacing: 0px;
                color: #262C2C;
                // border-bottom: 1px solid #dcdacb;
                box-shadow: 0 5px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);
                transition: all .2s ease-in;
                margin-bottom: .5rem;

                &:hover {
                    box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
                }

                &:active {
                }

                &:focus {
                }
            }

            .active-1 {
                background-color: #FFEAED;
                color: #D0021B;
                box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
            }
            
            .active-2 {
                background-color: #FFEAED;
                color: #D0021B;
                box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
            }



        }

        %label-input {
            label {
              
                letter-spacing: 0px;
                color: #262C2C;
            }

            input {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                border-radius: 5px;
                width: 100%;
                // max-width: 400px;
                // min-width: 250px;
                height: 46px;
                color: rgb(65, 64, 64);
                padding-left: 1rem;

            }
        }

        .sub--section{
        border:1px solid green;
            justify-content: space-between;
            width: 90%;
            margin: 0 auto;

            .sub--main{
                justify-content: space-between;
                .sub--main-1{
                border:1px solid yellow;
                    // align-items: center;
                    align-self: center;
                    // border: 1px solid blue;
                    .debit--input{
                        
                        padding: 0 0 1rem;
                        @extend%label-input;
                        
                        .flex--3{
                            label{
                                white-space: nowrap;
                            }
                            .debit{
                                // border: 2px solid green;
                                background-color: #FFEAED;
                                
                            }
                        }
                        .sub--section--2 {
                            grid-gap: 10px;

                        }
                        
                        .input{
                            // border: 1px solid blue;
                            align-self: flex-end;
                            margin-left: 1rem;
                        }
                    }

                    .label--input{
                        padding: 0 0 1rem;
                        @extend%label-input;
                    }
                }

                .sub--main-2{
                     border: 1px solid red;
                    .account--input{
                        padding: 0 0 1rem;
                        @extend%label-input;

                        .flex--3{
                            .account{
                                // border: 2px solid green;
                                background-color: #FFEAED;
                                
                            }
                        }
                        .input{
                            // border: 1px solid red;
                            align-self: flex-end;
                            margin-left:1rem;
                        }
                    }

                    .label--input{
                        padding: 0 0 1rem;
                        @extend%label-input;
                    }
                }
            }

            &--1{
                .label--input{
                    padding: 0 0 1rem;
                    @extend%label-input;
                } 
            }

            &--2{
                justify-content: space-between;
                .label--input{
                    padding: 0 0 1rem;
                    @extend%label-input;
                    width: 475px;
                }
            }
        }

        .button-last {
            width: 90%;
            margin: 0 auto;
            display: flex;

            button {
                background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 6px 16px #B522684D;
                border-radius: 5px;
                font: normal normal bold 16px/18px Ubuntu;
                letter-spacing: 0px;
                color: #FFFFFF;
                width: 180px;
                height: 44px;
                margin-top: 2rem;
                margin-left: auto;
            }
        }
    }
}