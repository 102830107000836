.mc--liquidation {
    
    
    .headerdiv{
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
        padding:15px;
        border-radius: 5px;
        color: white;
        
    
   p{
     font-weight: bold;
      font-size: 18px;
   } 
  }
   label {
    margin-left: 0px;
    margin-bottom: 0px;
  }
  input {
    width: 100%;
    min-width: 50%;
    height: 44px;
    margin-left: 10px;
    border-radius: 5px;
    margin: 4px 0;
  }
   select{
    width: 100%;
    min-width: 50%;
       height:44px;
       margin-left: 10px;
       border-radius: 5px;
       margin:4px 0;
   }

  //  .btn {
  //    border: 2px solid teal;
  //    height: 44px;
  //  }

  .btn{
    background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;  
    color:white;
    position:relative;
    width:160px;
    height: 44px;
    border-radius: 7px;
    margin-top: 30px;
    margin-bottom: 30px;
}

  .button--cont {
  
    width: 100%;
    justify-content: flex-end;
  }

   
}
.radiodiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    .productradio {
      min-width: 0;
      height: 15px;

      border-radius: 0;
      margin: 0;
    }
    .span {
      position: relative;
      top: 5px;
    }
  }

.pink{
    background-color: rgb(250, 237, 239);
}