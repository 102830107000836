.deal--posting {
  .heading{
      background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
      padding:15px;
      border-radius: 5px;
      color: white;
  
      p{
          font-size: 18px;
          font-weight: bold;
          
      }
  }

  select{
      height:44px;
      margin-left: 10px;
      border-radius: 5px;
      margin:1px 0;
      padding-left: 1rem;
      position: relative;
    }

  label {
      font: normal normal medium 18px/21px Ubuntu;
      letter-spacing: 0px;
      color: #262C2C;
      padding-bottom: 5px;
      margin-bottom: 0; 
  }

  input {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      border-radius: 5px;
      min-width: 300px;
      height: 44px;
  }

  .deal--ref{
    background: #FFEAED 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 5px;
  }

  .trade--date{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 5px;
  }

  .button {
      width: 100%;
      text-align: center;
      margin-top: 3rem;

      button {
          background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
          box-shadow: 0px 6px 16px #B522684D;
          border-radius: 5px;
          font: normal normal bold 16px/18px Ubuntu;
          letter-spacing: 0px;
          color: #FFFFFF;
          width: 160px;
          height: 44px;
      }
  }

  .section--1{
      .deal-post{
          grid-gap: 1rem;
          flex-wrap: wrap;
          margin-bottom: 10px;
          .flex--3 {
              flex: 1 1 150px;
            }
            
            .input--1 {
              max-width: 150px;
            }
          
            .input--2 {
              min-width: 200px;
              max-width: 500px
              
            }
            .input--3{
              max-width: 300px;
            }
            .input--4{
              max-width: 200px;
            }
            .input--5{
              max-width: 135px;
            }
            input {
              min-width: 10px;
            }
      }
      
      
      .price--1{
          .price--header{
              background: #FFEAED 0% 0% no-repeat padding-box;
              border-radius: 5px;
              opacity: 1;
              padding: 1rem;
              width: 22%;
              margin-bottom: 1rem;
          }
          span{
              color: #c2000a;
              
          }
      }
      
      // border-bottom: 1px solid grey;
      // padding-bottom: 5rem;

      
  }

  .button-last{
      width: 100%;
      margin-right: auto;
      // margin: 0 auto;
      display: flex;
      justify-content: flex-end;
     

      button{
          background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
          box-shadow: 0px 6px 16px #B522684D;
          border-radius: 5px;
          font: normal normal bold 16px/18px Ubuntu;
          letter-spacing: 0px;
          color: #FFFFFF;
          width: 180px;
          height: 46px;
          margin-top: 1rem;
          margin-bottom: 2rem
          
      }
  }

  
}