body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  scrollbar-width: thin;
  scrollbar-color: grey;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(177, 177, 177, 0.412);
  border-radius: 10px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.border--main {
  border-bottom: 1px solid #dcdacb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
}

input,
select {
  outline: none;
  border: 1px solid#302e2e;
  padding: 0px;
}

p {
  margin: 0;
}

button,
button:focus {
  outline: none;
  border: none;
  cursor: pointer;
}

.flex--1 {
  display: flex;
}

.flex--2 {
  display: flex;
  align-items: center;
}

.flex--3 {
  display: flex;
  flex-direction: column;
}

.flex--4 {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
}

.flex--7 {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 2rem;
}

.flex--5 {
  display: flex;
  justify-content: flex-end;
}

.flex--6 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid--1 {
  display: grid;
}
.grid--3 {
  display: grid;
  /* grid-auto-flow: row; */
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  justify-content: center;
  background-color: rgba(141, 141, 141, 0.213);
  color: rgba(255, 255, 255, 1);
}

button {
  cursor: pointer;
  outline: none;
  border: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.error--input {
  border: 2px solid red;
}
.error--input:focus {
  border: 2px solid red;
}

.error--icon {
  color: red;
  font-size: 1rem;
  margin-top: 0.7rem;
}

.error--both {
  font-size: 14px;
  white-space: nowrap;
  color: red;
  padding: 0.7rem 0.5rem 0;
}

.btn-cont {
  justify-content: flex-end;
  display: flex;
  margin: 1rem 0;
}

.input--amount {
  border: 1px solid black;
  height: 44px;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
