.loan--product{

  .headerdiv{
    background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
    padding:15px;
    border-radius: 5px; 
    color: white;


p{
   font-size: 18px;
   font-weight: bold;
}
   
} 

  label {
    margin-left: 0px;
    margin-bottom: 0px;
  }
  input {
    min-width: 100%;
    height: 44px;
    margin-left: 10px;
    border-radius: 5px;
    margin: 4px 0;
  }
  select{
    min-width: 100%;
    height:44px;
    margin-left: 10px;
    border-radius: 5px;
    margin:4px 0;
}
  .checboxdiv {
    display: flex;
     justify-content: space-between;
     align-items: center;
     width: 60px;
    .productcheckbox{
        min-width: 0;
    height: 15px;
    margin-left: 0;
    border-radius: 0;
    margin: 0;
    }
    .span{
        position: relative;
        top: 5px;
       
    }
  }
  .button {
    background: #FFEAED 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    font: normal normal normal 16px/18px Ubuntu;
    letter-spacing: 0px;
    color: #2A2A2A;
    width: 112px;
    height: 44px;
    align-self: flex-end;
  }
  .button-p {
    margin-top: 2rem;
    display:flex;
    justify-content: space-between;

    .button {
        border-radius: 50%;
        padding: 0;
        width: 44px;
        font-size: 1.5rem;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 5px #C2000A57;
        color: #C2000A;
    }

    

    p {
        font: normal normal 300 16px/21px Ubuntu;
        letter-spacing: 0px;
        color: #C2000A;
        padding: 1rem 0;
    }
    .button-sub{
      position:relative;
      top:-25px
    }
  }
  .next{
    background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;  
    color:white;
    position:relative;
    width:135px;
    height:44px;
    border-radius: 7px;
    margin-top: 40px;
    left: 85%;
    margin-bottom: 10px;
}
  .next-section--1 {
    padding: 2rem;

    input, select {
      min-width: 100px;
      margin: 0;
      padding-left: 10px;
      padding: 0; 
    }

    label {
      margin: 0;
      padding: 0;
    }

    .sub-input {
      align-self: flex-end;
      width: 100%;
    }

    .label--main {
      grid-gap: 1rem;
      // flex-wrap: wrap;

      .label--container {
        grid-gap: 1rem;
        width: 450px;
        min-width: 300px;

        .label--input {
          width: 100%;
        }

      }
    }

    .section--1 {
      grid-gap: 1rem;
      grid-template-columns: repeat(auto-fit, minmax(170px, 100px));
      align-items: center;
      padding: 2rem 0;

      input {
        min-width: 10px;
      } 
  
      label {
        margin: 0;
      }
      .flex--2 {
        white-space: nowrap;
  
        input {
          margin-right: 10px;
        }
      }
    }

    .last-section {
      margin: 1rem 0;
      
      .label--main {
        grid-gap: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
      }
      
      .sub--section {
        padding: 2rem;
        border: 2px solid #707070;
        grid-gap: 1rem;
      }
    }
  }

  #button--cont {
    justify-content: flex-end;
    margin: 2rem 0;
  }

  .next-section--2 {
    padding: 2rem 2rem 0;


    .heading-container {
      width: 80%;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 1rem;
    }

    input, select {
      min-width: 10px;
      margin: 0;
      padding: 0;
      padding-left: 1rem;
    }

    label {
      margin: 0;
      padding: 0;
    }

    .label--input {
      width: 300px;

      label {
        font: normal normal medium 18px/21px Ubuntu;
        letter-spacing: 0px;
        color: #262C2C;
      }
    }

    .side-container {
      grid-gap: 1rem;
    }

    .side--1 {
      border: 2px solid #707070;
      border-radius: 5px;
      width: 50%;
      margin: 2rem 0;
      padding: 2rem;
      grid-gap: 1rem;

      // input {
      //   flex: 1 1;
      // }

      .heading {
        width: 80%;
        justify-content: space-between;

        h5 {
          font: normal normal normal 16px/18px Ubuntu;
          letter-spacing: 0px;
          color: white;
        }
      }

      .label-cont {
        grid-gap: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        
        &--1 {
          grid-template-columns: minmax(50px, 50px) repeat(auto-fit, minmax(180px, 1fr));

          .input--1 {
            max-width: 50px;
            text-align: center;
          }

          select {
            max-width: 220px;
            padding: 0 1rem 0 10px;
          }

          button {
            align-self: flex-start;
          }
        }
      }
    }
    
  }

  .next-section--3 {
    padding: 2rem 2rem;

    button {
      background-color: transparent;
      box-shadow: none;
    }

    input, select {
      min-width: 10px;
      margin: 0;
      padding: 0;
      padding-left: 1rem;
    }

    label {
      margin: 0;
      padding: 0;

    }

    .section--1 {
      .main {
        grid-gap: 1rem;
        flex-wrap: wrap;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      }
    }

    .section--2 {
      padding: 2rem 0;
      display: flex;
      justify-content: space-between;

      .customer-restriction{
        width: 50%
      }

      .loan-contribution-product{
        width: 40%;
      }
      
      .sub--section {
        padding: 2rem 4rem;
        border: 2px solid rgb(48, 45, 45);
        
        .selecter {
          width: 205px
        }

        .label--input {
          width: 50%;
          max-width: 200px;
          min-width: 200px;
        }

        .button--input {
          margin: 1rem 0;
          grid-gap: 1rem;



          .gapper {
            width: 80%;
            grid-gap: 2rem;
            flex-wrap: wrap;
            input {
              min-width: 150px;
            }
          }

          button {
            background: #FFEAED 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 5px;
            font: normal normal normal 16px/18px Ubuntu;
            letter-spacing: 0px;
            color: #2A2A2A;
            width: 112px;
            height: 44px;
            align-self: flex-end;
          }
          
          .button-add-cont {
            align-items: center;
            width: 150px;
    
            p {
              color:#D0021B; 
              padding: 0;
              padding-top: 1rem;
            }
    
            
          }
          .button-add {
            border: 2px solid #D0021B;
            align-self: center;
            // align-self: flex-start;
            height: 44px;
            width: 60px;
            border-radius: 50%;
            background-color: white;
            color:#D0021B; 
            font-size: 2rem;
          }
        }
      }
    }

    .section--3 {
      border-bottom: 2px solid rgb(48, 45, 45);
      padding: 1rem 0 5rem 0;
    }

  

  }

  input, select {
    min-width: 100%;
    height: 44px;
    padding-left: 10px
  }

  .view-container {
    #button--cont {
      justify-content: center;

      button:not(:first-child) {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        color: white;
      }
    }
  }
  
  .sub--section-z {
    button {
      background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
      color: white;
      width: 155px;
    }
  }


}