.account-statement {
  .row-div {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
  }

  input {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 5px;
    width: 100%;
    max-width: 200px;
    // min-width: 250px;
    height: 44px;
    color: rgb(65, 64, 64);
    padding-left: 1rem;
    // margin: 4px 0;
  }
  label {
    margin-bottom: 0;
  }

  // .accountStatementButtons{
  //     display: flex;
  //     justify-content: space-between;
  // }

  .accountStatementButtons {
      display: flex;
      justify-content: space-between;
      .rest-search-buttons {
          display: flex;
        .search-btn {
          margin-left: 50px;
          color: white;
          background: transparent
            linear-gradient(137deg, #d0021b 0%, #68010e 100%) 0% 0% no-repeat
            padding-box;
          padding: 0px 10px;
          border-radius: 5px;
          height: 40px;
          margin-top: 25px;
        }
        .reset-btn {
          // margin-left: 50px;
          color: #d0021b;
          background: none;
          padding: 0px 10px;
          border-radius: 5px;
          height: 40px;
          margin-top: 25px;
          border: 2px solid #d0021b;
        }
      }

      .generate-download-buttons {
        display: flex;
        .search-btn {
            margin-left: 2rem;
            color: #d0021b;
            background: none;
            padding: 0px 10px;
            border-radius: 5px;
            height: 40px;
            margin-top: 25px;
            border: 2px solid #d0021b;
        }
        .reset-btn {
            color: white;
            background: transparent
              linear-gradient(137deg, #d0021b 0%, #68010e 100%) 0% 0% no-repeat
              padding-box;
            padding: 0px 10px;
            border-radius: 5px;
            height: 40px;
            margin-top: 25px;
        }
      }
  }

  .row-div-2 {
    display: flex;
    margin-bottom: 40px;
    margin-top: 30px;
    margin-left: 20px;
  }
  .row-div-21 {
    margin-left: 20px;
  }
  .table-fill {
    background-color: #d0021b;
    color: white;
  }
  .print-btn {
    background-color: #d0021b;
    color: white;
    border-radius: 5px;
    height: 40px;
    margin-top: 25px;
    margin-left: 40%;
    padding: 0px 20px;
  }

  .pink {
    background-color: rgb(250, 237, 239);
  }
  .error--container {
    position: relative;

    small {
      position: absolute;
      left: 0;
      bottom: -5px;
      color: red;
    }

    .importantInput {
      color: #d0021b;
    }
  }
}
