.banking--codes {
  .headerdiv {
    background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
    padding: 15px;
    border-radius: 5px;

    p {
      font-size: 18px;
      color: white;
      font-weight: bold;
    }
  }
  label {
    margin: 0;
  }

  input {
    height: 44px;
    padding-left: 10px;
    border-radius: 5px;
    margin: 4px 0 0;
  }

  select {
    height: 44px;
    padding-left: 10px;
    border-radius: 5px;
    margin: 4px 0 0;
  }

  .container--flex {
    .section--1 {
      flex-wrap: wrap;
      grid-gap: 1rem;
      margin-bottom: 2rem;

      .flex--3 {
        flex: 1 1 150px;
        max-width: 400px;
        min-width: 100px;
      }

      // For flat days alone 
      .flat-days {
        // flex: flex-grow flex-shrink flex-basis
        flex: 0 1 80px;
        min-width: 80px;
      }


    }

    .section--2 {
      .clearingbox {
        border: 2px solid rgb(17, 17, 17);
        padding: 1rem;
        border-radius: 4px;

        .button-flex {
          align-items: center;
          width: 80px;
          margin-top: 1rem;
        }

        .add {
          border-radius: 40px;
          width: 28px;
          background-color: white;
          color: #d0021b;
          box-shadow: 2px 2px 2px 2px #ebacb3;
          margin-bottom: 8px;
        }


      }
    }

    .clearingbox1 {
      flex-wrap: wrap;
      grid-gap: 1rem;

      .flex--3 {
        flex: 1 1 150px;
        max-width: 200px;
        min-width: 150px;
      }

      .button--down {
        align-self: flex-end;

        .delete {
          width: 80px;
          background-color: pink;
          cursor: pointer;
          height: 44px;
          border-radius: 5px;
        }
      }
    }


  }

  .last-section {
    justify-content: flex-end;
  }


  .save {
    background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
    color: white;
    position: relative;
    width: 130px;
    height: 44px;
    border-radius: 7px;
    margin-top: 40px;
    margin-bottom: 30px;
  }
}