.loan--booking {

    .headerdiv {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        padding: 15px;
        border-radius: 5px;
        color: white;

        p {
            font-size: 18px;
            font-weight: bold;
        }
    }

    .loanFacilityLimit{
        display: none;
    }


    .button--double {
        width: 100%;
        margin: 0 auto;
        justify-content: center;
        gap: 5rem;
        padding: 0 0 2rem 0;

        .flex--3 {
            align-items: center;
            margin-top: 30px;

            .button-name {
                color: black;
            }

        }

        button {
            border-radius: 50%;
            padding: .5rem 1rem;
            border: 3px solid #D0021B;
            width: 48px;
            height: 44px;
            font: normal normal medium 24px/28px Ubuntu;
            font-size: 20px;
            letter-spacing: 0px;
            color: #262C2C;
            // border-bottom: 1px solid #dcdacb;
            box-shadow: 0 5px 4px rgba(0, 0, 0, .08), 0 4px 12px rgba(0, 0, 0, .08);
            transition: all .2s ease-in;
            margin-bottom: .5rem;

            &:hover {
                box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
            }

        }

        .active-1 {
            background-color: #FFEAED;
            color: #D0021B;
            box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
        }

        .active-2 {
            background-color: #FFEAED;
            color: #D0021B;
            box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
        }



    }

    label {
        margin-left: 0px;
        margin-bottom: 0px;
    }

    input[type=date],
    input[type=text],
    input[type=number],
    input[type=email],
    input[type=tel],
    select {
        width: 100%;
        height: 44px;
        margin-left: 10px;
        border-radius: 5px;
        margin: 4px 0;
        width: 100%;
        max-width: 363px;
    }

    .charges-section {
        margin: 1rem 0;

        .label--main {
            grid-gap: 1rem;
            grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
        }

        .sub--section {
            padding: 2rem;
            border: 2px solid #707070;
            grid-gap: 1rem;
        }

        button {
            background: #FFEAED 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 5px;
            font: normal normal normal 16px/18px Ubuntu;
            letter-spacing: 0px;
            color: #2A2A2A;
            width: 112px;
            height: 44px;
        }

        .button-p {
            margin-top: 2rem;
            display: flex;
            justify-content: space-between;

            .button {
                border-radius: 50%;
                padding: 0;
                width: 44px;
                font-size: 1.5rem;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 5px #C2000A57;
                color: #C2000A;
            }



            p {
                font: normal normal 300 16px/21px Ubuntu;
                letter-spacing: 0px;
                color: #C2000A;
                padding: 1rem 0;
            }

            .button-sub {
                position: relative;
                top: -25px
            }
        }
    }

    //    select{
    //     min-width: 363px;
    //     width: 100%;
    //     height:44px;
    //     margin-left: 10px;
    //     border-radius: 5px;
    //     margin:4px 0;

    // }
    .checboxdiv {
        display: flex;
        align-items: center;
        //position:relative;


    }

    .borrowed-class {
        margin-top: 50px;
        flex-wrap: wrap;
        

        .repaymentmerge{
            width: 90%;
            // border: 1px solid red;

            input, select{
                width: 80%;
            }
        }
        .label-input {
            width: 30%;
            margin: 0 5px 15px;
            min-width: 250px;
            max-width: 300px;

            label {
                white-space: nowrap;
            }

            .span1 {
                position: relative;
                top: 5px;
                right: 58%;

            }

            .input-span {
                grid-gap: 1rem;
                position: relative;

                .span-abs {
                    background-color: #FFEAED;
                    height: 100%;
                    // padding: 1rem;
                    height: 40px;
                    position: absolute;
                    top: 5px;
                    left: 2px;
                    padding: 10px 10px 0 2px;
                    width: 40px;
                    text-align: center;
                    border-radius: 4px 0 0 4px;
                }

                .input-abs {
                    padding-left: 55px;
                }
            }

        }

        .borrowed-class {
            margin-top: 50px;
            flex-wrap: wrap;


            .label-input {
                width: 30%;
                margin: 0 5px 15px;
                min-width: 250px;
                max-width: 300px;


                label {
                    white-space: nowrap;
                }

                .input-span {
                    width: 100%;
                    height: 44px;
                    grid-gap: 1rem;

                    input,
                    select {
                        // flex: 1 1 40%;
                        height: 100%;
                        width: 100%;
                        border: 1px solid #707070;
                        border-radius: 5px;
                        margin: 0;
                    }

                    .span-input {
                        border-radius: 0 5px 5px 0;
                        border-left: none;

                    }

                    span {
                        background: #FFEAED 0% 0% no-repeat padding-box;
                        border-radius: 5px 0px 0px 5px;
                        height: 103%;
                        width: 50px;
                        text-align: center;
                        padding-top: 15px;
                        font: normal normal normal 14px/16px Ubuntu;
                        letter-spacing: 0px;
                        color: #C2000A;
                    }
                }
            }
        }

        .input__button{
            // display: flex;
            // justify-content: space-between;
            .checboxdiv1 {
    
                
                display: flex;
                justify-content: space-between;
                position: relative;
                align-self: flex-start;
    
                .boxdiv {
                    width: 70%;
                    display: flex;
    
                    .checkbox {
                        border: 1px solid red;
                        height: 15px;
                        border-radius: 0;
                        // position: relative;
                        right: 40px;
                    }
    
                    .name {
                        position: relative;
                        right: 80px;
                        top: 8px;
                    }
                }
            }
        }

        .input--span3 {
            width: 100%;
            min-width: 100px;
            height: 44px;
            border-radius: 4px;
            overflow: hidden;
            position: relative;

        }

        .input--span4 {
            width: 100%;
            min-width: 100px;
            height: 44px;
            border-radius: 4px;
            overflow: hidden;
            position: relative;

        }

        .input-qwer {
            border: 2px solid blue;
            position: relative;
            height: 44px;
            overflow: hidden;

            // span {
            //     @extend %span;
            // }
            .input--span2 {
                width: 100%;
                min-width: 100px;
                height: 44px;
                border-radius: 4px;
                overflow: hidden;
                position: relative;
                bottom: 10px;
                margin-left: 3rem;
            }
        }

        h3 {
            margin-top: 100px;
        }

        .charges {
            border: 2px solid grey;
            border-bottom: none;
            border-radius: 4px;
            padding: 30px;
            display: grid;
            grid-gap: 1rem;
            grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));


            .checkbox {
                height: 15px;
                border-radius: 0;
                position: relative;
                // right:190px;
                top: 65px;
            }

            .option {
                min-width: 145px;
                position: relative;
                // left:20px;
            }
        }

        .add {
            display: flex;
            justify-content: space-between;
            border: 2px solid grey;
            border-radius: 0 4px 4px 0;
            border-top: none;
            padding-bottom: 30px;

            .addbtn {
                width: 40px;
                background-color: white;
                color: #D0021B;
                box-shadow: 2px 2px 2px 2px grey;
                border-radius: 4px;
            }

            .btndiv {
                position: relative;
                left: 85%;
            }

            .deldiv {
                position: relative;
                right: 3%;
            }

        }

        .previous {
            background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
            color: white;
            position: relative;
            width: 180px;
            height: 40px;
            border-radius: 7px;
            margin-top: 40px;
            left: 60%;
            margin-bottom: 30px;
        }

        .view {
            //   background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;  
            color: #D0021B;
            background-color: white;
            border: 1px solid #D0021B;
            position: relative;
            width: 150px;
            height: 40px;
            border-radius: 7px;
            margin-top: 40px;
            margin-bottom: 30px;
        }

        .check-div {
            display: flex;

            justify-content:center;
            .checkbox-noncust {
                border: 1px solid red;
                width: 15px;
                margin-right: 1rem;
                margin: 0;
                position: relative;
                top: -10px;

                cursor: pointer;

            }

        }

    }
    
    .next-section--3 {
        margin-top: 3rem;
    }
    .flex--button {
        justify-content: flex-end;
    }

    .next--button, .view--button {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        color: white;
        position: relative;
        width: 180px;
        height: 44px;
        border-radius: 7px;
        margin-top: 40px;
    }

    .view--button {
        margin-top: 0;
    }



    .addbtn--1 {

        button {
            // width: 130px;
            background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
            color: white;
            position: relative;
            width: 180px;
            height: 44px;
            border-radius: 7px;
            margin-top: 10px;
        }
    }

    .button--booking {
        justify-content: flex-end;
        grid-gap: 1rem;

        button {
            background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
            color: white;
            position: relative;
            width: 130px;
            height: 44px;
            border-radius: 5px;
            margin-top: 20px;
        }


    }
}


