.chartof--account {
    
    .error-container-z .error--p .icon {
        margin-top: 0px;
    }

    .error-container-z .error--p {
        bottom: -9px;
    }

    .select--z {
        margin-bottom: 10px;
        height: 100%;
        .error--p {
            bottom: -18px;
            left: 10px;
        }
    }
    .select-z-1 {
        margin-bottom: 10px;
    }

    td, th {
        // text-align: center;
    }
        
    
    

    .headerdiv{
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
        padding:15px;
        border-radius: 5px;
        color: white;
    
    p{
       font-size: 18px;
       font-weight: bold;
    } 
}
   select{
       min-width: 100%;
       height:44px;
       margin-left: 10px;
       border-radius: 5px;
       margin: 4px 0px 10px;
       padding-left: 1rem 
   }
   label{
       margin-left: 0px;
       margin-bottom: 0;
   }
   input{
    min-width: 100%;
    height:44px;
    margin-left: 10px;
    border-radius: 5px; 
    margin: 4px 0px 10px; 
   }
   .entry-type, .status,.recon-type{
    background-color: rgb(230, 201, 206);
    width: 100%;
    padding: 0px 15px;
    border-radius: 5px;
   }
   .entry-type {
       flex-wrap: wrap;

       &--1 {
           margin-bottom: 7px;
       }

       .label-input {
           padding-right: 1.5rem;
        }
        
        label {
            font-size: 16px;
            margin-bottom: 0;
       }
       
        input {
            min-width: 10px;
            width: 15px;
            // height: 80px;
            margin: 0px .5rem;
            // transform: translateY(0px);
            cursor: pointer;
        }
   }

  


   .savebtn{ 
    width: 100%;
    margin: 25px auto;
    padding: 0 0;
    justify-content: center;



      .save{
            width: 15%;
            min-width: 137px;
            height: 44px;
            background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
            color:white;
            border-radius: 5px;
        }
        .cancel{
            width: 15%;
            min-width: 137px;
            height: 44px;
            margin-left: 19px;
            border:2px solid  #D0021B; ;
            color: #D0021B;;
            border-radius: 5px;
            background-color: white;

      }
    }

    .adj--icon {
        margin-left:15px;
    }

    .auth-body {
        width: 100%;
        flex-wrap: wrap;

        input, select {
            width: 100%;
            border: 1px solid rgb(26, 25, 25);
            max-width: 500px;
            min-width: 100px;
        }
    }

    .sub--section-z {
        margin-bottom: 1rem;
  }
  .main-filter-z {
        margin: 0rem 0 0rem auto; 
        
        input  {
            margin: 0rem 0 0rem auto;
            border-radius: 5px;
            width: 40%;
            min-width: 20px;
            height: 44px;
            padding:  0 0 0 1rem;
        }

        button {
            background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
            border-radius: 5px;
            font: normal normal bold 16px/18px Ubuntu;
            letter-spacing: 0px;
            color: #FFFFFF;
            width: 160px;
            height: 44px;
            margin: 0 2rem;

            &:nth-child(3) {
                background: transparent;
                font: normal normal bold 16px/18px Ubuntu;
                color: #D0021B;
                border: 2px solid #D0021B;
                width: 200px;
                margin-right: 0;
            }

        }
    }

    @media screen and (max-width: 800px) {
        .chartof--account .entry-type .label-input {
            padding-right: 1rem;
        }
    }
}



