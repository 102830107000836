.branch--calendar {
    // background-color: whitesmoke;

    .react-calendar {
        width: 100%;
        max-width: 700px;
        font-family: Arial, Helvetica, sans-serif;
        line-height: 1.125em;
        text-align: center;
        margin: 2rem auto 0;
    }

    .inputs-container {
        padding: 3rem;
    }

    label {
        font: normal normal medium 18px/21px Ubuntu;
        letter-spacing: 0px;
        color: #262C2C;
        padding-bottom: 4px;
        margin-bottom: 0;
    }

    input, select {
        border: 1px solid #707070;
        border-radius: 5px;
        height: 44px;
        padding-left: 10px;
    }

    .heading {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        padding: 15px;
        border-radius: 5px;

        p {
            font-size: 18px;
            color: white;
            font-weight: bold;
        }

    }

    .button {
        width: 100%;
        text-align: center;
        margin: 2rem 0 1rem;

        button {
            background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
            box-shadow: 0px 6px 16px #B522684D;
            border-radius: 5px;
            font: normal normal bold 16px/18px Ubuntu;
            letter-spacing: 0px;
            color: #FFFFFF;
            width: 160px;
            height: 44px;
        }
    }

    .calendar {
        margin: 2rem 0 0;
        justify-content: center;
    }

    .DayPicker {
        display: inline-block;
        font-size: 1rem;
        background: #ffffff;
        border-radius: 10px;
        border-bottom: 1px solid #dcdacb;
        box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);
    }

    .DayPicker-Month {
        width: 28rem;
    }

    .DayPicker-Day {
        display: table-cell;
        padding: 0.5em;
        border-radius: 20%;
        vertical-align: middle;
        text-align: center;
        cursor: pointer;
        margin: 55px;
        border: 8px solid white;
    }

    .DayPicker-Day--disabled {
        pointer-events: none;
    }

    .section--holiday {
        padding: 0 3rem 1rem;

        .flex--4 p {
            background-color: rgb(242, 216, 220);
            // border: 1px solid rgb(184, 184, 184);
            outline: none;
            font-size: 14px;
            border-radius: 4px;
            padding: 7px 10px;
        }

        .holidays {
            margin-bottom: 1rem;
            position: relative;
            margin-right: 2rem;

            &::after {
                content: "";
                position: absolute;
                height: 70%;
                width: 1px;
                background-color:#0c0c0c8a;
                right: -25px;
                top: 32%
            }
            
        }
    }

    .month--view {
        p {
            background-color: #f2d8dc;
            border: 1px solid #b8b8b8;
            outline: none;
            font-size: 14px;
            border-radius: 4px;
            padding: 10px 30px 0 10px;
            height: 44px;
        }
    }
}