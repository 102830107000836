.investment--liquidation {


    .headerdiv {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        padding: 15px;
        border-radius: 5px;
        color: white;

        p {
            font-weight: bold;
            font-size: 18px
        }
    }

    select {
        width: 100%;
        height: 44px;
        margin-left: 10px;
        border-radius: 5px;
        margin: 4px 0;
    }

    label {
        margin-left: 0px;
        margin-bottom: 0;
    }

    input {

        width: 100%;
        height: 44px;
        margin-left: 10px;
        border-radius: 5px;
        margin: 4px 0;

    }

    .checkbox {
        height: 14px;
        position: relative;
        right: 150px;
        top: 30px;
    }

    .checkboxlabel {
        position: relative;
        left: 130px
    }

    .interestinput {
        width: 30px;
    }

    .button--cont {
        justify-content: flex-end;
    }


    .btnz {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        color: white;
        // width:130px;
        height: 44px;
        padding: 0 1rem;
        border-radius: 7px;
        margin-top: 80px;
        margin-bottom: 30px;
    }

    h1 {
        font: normal normal bold 30px/28px Ubuntu;
        color: #262C2C;
        padding: 1rem 0
    }

    .sub--section {
        position: relative;
        right: 0;


        .main-filter {
            margin: 1rem 0 2rem auto;

            .filter {
                border: 1px solid #B52268;
                border-radius: 5px;
                width: 30px;
                min-width: 150px;
                height: 44px;
                padding: 0 0 0 1rem;
            }

            button {
                background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
                border-radius: 5px;
                font: normal normal bold 16px/18px Ubuntu;
                letter-spacing: 0px;
                color: #FFFFFF;
                width: 160px;
                height: 44px;
                margin: 0 2rem;

                &:nth-child(3) {
                    background: transparent;
                    font: normal normal bold 16px/18px Ubuntu;
                    color: #D0021B;
                    border: 2px solid #D0021B;
                    width: 200px;
                    margin-right: 0;
                }

            }
        }

        table {
            width: 100%;
            font: 14px;
            white-space: nowrap;
        }

        td,
        th {
            text-align: left;
            padding: 8px;
            border: none;
        }

        .adj-icon {
            margin-left: 10px;
        }

        .table--icon {
            margin-left: 1rem;

            &--2 {
                margin-left: .5rem;
            }
        }
    }
}