.Loan--disbursement{
    

    .headerdiv{
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
        padding:15px;
        border-radius: 5px;
        color: white;
    
   p{
       font-size: 18px;
       font-weight: bold;
   }
}
   select{
       width: 100%;
       height:44px;
       margin-left: 10px;
       border-radius: 5px;
       margin: 4px 0px;
   }
   label{
       margin-left: 0px;
       margin-bottom: 0px;
   }
   input{
    width: 100%;
    height:44px;
    margin-left: 10px;
    border-radius: 5px; 
    margin: 4px 0px; 
   }
   .sub-main{
    .sect1{
        display: flex;
        justify-content: space-between;
        padding: 25px;
        background-color: grey;
        width: 100%;
    }
    input {
                    height: 46px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 1px solid #707070;
                    border-radius: 6px;
                    margin: 0 1rem;
                    width: 60%;
                    max-width: 150px;
                    text-align: center;
                    min-width: 30px;
                } 
}
   
.btnz{
    background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;  
    color:white;
    position:relative;
    width:180px;
    height:44px;
    border-radius: 7px;
    margin-top: 110px;
    
    margin-bottom: 30px;
}
.btn--1{
    background: transparent;
    color: #D0021B;
    border: 2px solid #D0021B;
    position:relative;
    width:180px;
    height:44px;
    border-radius: 7px;
    margin-top: 110px;
    left:48%;
    margin-bottom: 46px;
    top:30px;
}
.btn--2{
    background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;  
    color:white;
    position:relative;
    width:180px;
    height:44px;
    border-radius: 7px;
    margin-top: 110px;
    left:50%;
    margin-bottom: 46px;
    top:30px;
}

.section--2 {
    padding: 4rem 0 0 0;
    border-top: 1px solid grey;

    h1 {
        font: normal normal bold 30px/28px Ubuntu;
        color: #262C2C;
        padding: 1rem 0
    }

    .sub--section {
        position: relative;
        right: 0;
    }

    .main-filter {
        margin: 1rem 0 2rem auto;

        input  {
            border: 1px solid #B52268;
            border-radius: 5px;
            width: 300px;
            height: 44px;
            padding:  0 0 0 1rem;
            
        }

        button {
            background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
            border-radius: 5px;
            font: normal normal bold 16px/18px Ubuntu;
            letter-spacing: 0px;
            color: #FFFFFF;
            width: 120px;
            height: 44px;
            margin: 0 2rem;

            &:nth-child(3) {
                background: transparent;
                font: normal normal bold 16px/18px Ubuntu;
                color: #D0021B;
                border: 2px solid #D0021B;
                width: 120px;
                margin-right: 0;
            }

        }
    }
    .section2{
        display: flex;
        justify-content: space-between;
        input{
            width: 100%;
            max-width: 200px;
            border: 2px solid red;
            position: relative
        }
    }
    table {
        width: 100%;
        font: 14px;
        white-space: nowrap;
    }

    td, th {
        text-align: center;
        padding: 8px;
        border: none;
    }

    // tr:nth-child(even) {
    //     background-color: #8f8c8c;
    // }

    .table--icon {
        margin-left: 1rem;

        &--2 {
            margin-left: .5rem;
        }
    }
}
}
