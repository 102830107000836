.customer--debit{
    

    .headerdiv{
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
        padding:15px;
        border-radius: 5px;
        color: white;
    
   p{
       font-size: 18px;
       font-weight: bold;
   } 
}
.input-container--1{
    grid-gap:1rem;

    .acctBalance{
        background: #e7949e;
    }

    .customerDebitDetails{
        background: #FFEAED;
        padding: 1rem;
        color: #D0021B;
        border-radius: 5px;
    }


}
   select{
       min-width: 100%;
       height:44px;
       margin-left: 10px;
       border-radius: 5px;
       margin:4px 0;
   }
   label{
       margin-left: 0px;
       margin-bottom: 0px;
   }
   input{
    width: 100%;
    height:44px;
    margin-left: 10px;
    border-radius: 5px; 
    margin:4px 0; 
   }
   .btn{
       background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;  
       color:white;
       position:relative;
       width:180px;
       height:44px;
       border-radius: 7px;
       margin-top: 80px;
       left:70%;
       margin-bottom: 30px;
   }
   .pink{
       background-color: rgb(250, 237, 239);
   }
   .error--container {
    position: relative;

    small {
        position: absolute;
        left: 0;
        bottom: -5px;
      color:red;
    }
}
}
