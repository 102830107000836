.account-enquiry {

    .addProductButton{
        font: normal normal medium 18px/21px Ubuntu;
                color: #D0021B;
                box-shadow: none;
                width: 100px;
                height: 44px;
                background: transparent linear-gradient(137deg, #D69BB7 0%, #D69BB7 100%) 0% 0% no-repeat padding-box;
                color: white;
                border-radius: 5px;
                cursor: pointer;
    }

    html {
        scroll-behavior: smooth;
    }

    .heading {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        padding: 15px;
        border-radius: 5px;
        color: white;
        font-size: 18px;
        font-weight: bold;
    }

    .section--main {
        height: 100%; 
        background-color: rgb(248, 248, 248);
        padding: 15px;
        
        .sub-section, .input-button {
            grid-gap: 1rem;
            flex-wrap: wrap;
            justify-content: flex-end;
        }

        .not--loaded {
            grid-gap: .5rem;
            margin: 12rem 0;
            align-self: center;
            
            .circle-error {
                font-size: 36px;
                color: #ac0116;
                // margin: 1rem 0;
            }

            h4 {
                margin: 0;
                font-size: 18px;
            }
        }
        .editbtn{
            border:1px solid black;
            margin:auto;
        }

        input {
            border: 1px solid rgb(26, 25, 25);
            max-width: 500px;
            min-width: 100px;
            height: 44px;
            border-radius: 5px;
        }

        .sub-section {
            button {
                font: normal normal medium 18px/21px Ubuntu;
                color: #D0021B;
                box-shadow: none;
                width: 100px;
                height: 44px;
                background: transparent linear-gradient(137deg, #D69BB7 0%, #D69BB7 100%) 0% 0% no-repeat padding-box;
                color: white;
                border-radius: 5px;
                cursor: pointer;
                
                &:disabled {
                    cursor: not-allowed;
                    background: rgb(191, 191, 191);
                }
            }
        }
       

    }

    .account--info {
        padding: 30px 15px;

        input {
            display: none;
        }

        .details-flex {
            flex-wrap: wrap;
            color: rgb(29, 29, 29);
            flex-wrap: wrap;
            grid-gap: 1rem 26px;
            transition: all .1s ease-in;   
            visibility: hidden;
            // display: none;
            height: 0;
            background: #ffffff;
            // margin-left: 10px;
            margin: 7px 10px 0px;
            padding: 0 1rem;


            label {
                font-size: 15px;
                font-weight: 400;
                margin-bottom: 0;
                white-space: nowrap;
            }
    
            p {
                background-color: rgb(242, 216, 220);
                border: 1px solid rgb(184, 184, 184);
                outline: none;
                font-size: 14px;
                border-radius: 4px;
                padding: 7px 10px;
            }
        }

        [id^=btn]:checked + .details-flex { 
            height: 100%;
            visibility: visible;
            transition: all .1s ease-in;
            padding: 1rem; 
            padding-bottom: 2rem;
            margin: 5px 10px 7px;
        }
        
        .each-label {
            background-color: rgb(255, 255, 255);
            width: 100%;
            padding: 1rem;
            cursor: pointer;
            transition: all .1s ease-in;

            &:hover {
                background-color: #FFEAED;
                color: #D71920
            }
        }
        
    }

    .section--table {
        h4 {
            margin-top: 2rem;
        }

        
        .main-filter {
            margin: 1rem 0 2rem auto;
        
            .filter {
                border: 1px solid #B52268;
                border-radius: 5px;
                width: 30px;
                min-width: 150px;
                height: 44px;
                padding: 0 0 0 1rem;
            }
            
            button {
                background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
                border-radius: 5px;
                font: normal normal bold 16px/18px Ubuntu;
                letter-spacing: 0px;
                color: #FFFFFF;
                width: 160px;
                height: 44px;
                margin: 0 2rem;
        
                &:nth-child(3) {
                    background: transparent;
                    font: normal normal bold 16px/18px Ubuntu;
                color: #D0021B;
                border: 2px solid #D0021B;
                width: 200px;
                margin-right: 0;
                }
                
            }
        }

        
        button {
            color: #202020;
            
            &:disabled {
                background-color: transparent;
                color: #a9a9a9;
            }
        }
    }
    .new-grid {
        margin: 2rem;
        padding: 0 1rem;
        grid-gap: 1rem;
        flex-wrap: wrap;

        h5 {
            padding-left: 50px;
            padding-top: 30px;
        }

        .input--select {
            flex: 1 1 210px;
            max-width: 300px;

            select {
                height: 44px;
                border-radius: 5px;
                width: 100%;
            }
        }

        .input--field {
            flex: 1 1 210px;
            max-width: 300px;

            input {
                height: 44px;
                border-radius: 5px;
                width: 100%;
            }
        }

        label {
            margin-bottom: -0.5rem;
        }
    }

    .new {
        label {
            margin-bottom: -0.5rem;
        }
        input, select {
            height: 44px;
            border-radius: 5px;
            width: 100%;
        }
    }


} 