.account--product { 
  margin-bottom: 4rem;

  .productcheckbox{
    border: 1px solid red;
    min-width: 0;
    height: 15px;
    margin-left: 0;
    border-radius: 0;
    margin: 0;
  }

  .mobileApplicable, .requireMemberShipFee, .isEligibleForLoan{
    border: 1px solid red;
    min-width: 0;
    height: 15px;
    margin-left: 0;
    border-radius: 0;
    margin: 0;
  }
  
  .headerdiv{
    background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
    padding:15px;
    border-radius: 5px;
    color: white;

p{
   font-size: 18px;
   font-weight: bold;
}
} 

  label {
    margin-left: 0px;
    margin: 0;
  }
  input { 
    min-width: 100%;
    height: 44px;
    margin-left: 10px;
    border-radius: 5px;
    margin: 1px 0;
    position: relative;
    // z-index: -1;
  }
  select{ 
    // z-index: -1;
    min-width: 100%;
    height:44px;
    margin-left: 10px;
    border-radius: 5px;
    margin:1px 0;
    padding-left: 1rem;
    position: relative;
}
  .checboxdiv {
    width: 200px;
    grid-gap: .5rem;
    margin: 1rem 0 1rem 1rem;

    .span{
        position: relative;
        // top: 5px;
       
    }
  }

  .debitdiv{
    display: flex;
    // justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    margin: 1rem 0;
    flex-wrap: wrap;
    grid-gap: 1rem 1.5rem; 

    .flex--2 {
      grid-gap: 4px;
      min-width: 100px;

    }

    // top: 30px;
   
     
     .debitcheckbox {
        min-width: 0;
        height: 15px;
        margin-left: 0;
        border-radius: 0;
        margin: 0; 
     }   
  }
  .spandiv {
    display: flex;
    align-items: center;
    position: relative;
    top: 30px;
    margin-bottom: 20px;
    grid-gap: 5px;

    .spanbox {
      min-width: 0;
      width: 15px;
      height: 20px;
      margin-left: 0;
      border-radius: 0;
      margin: 0; 
      position: relative; 
      left: -8px;
  }
  }
  .span3{
    position: relative;
    left: -5px;
}
.next{
    background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;  
    color:white;
    position:relative;
    width: 150px;
    height:44px; 
    border-radius: 4px;
}

.next-cont {
  display: flex;
  justify-content: flex-end;
  // width: 95%;
  margin-top: 2.5rem;
}

.main-section--2 {
  padding: 2rem;

  input, select {
    margin: 0;
    padding: 0 1rem;
  }

  p {
    margin: 0;
    padding: 0;
  }

  .row-container {
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    
    &--3 {
      grid-template-columns: repeat(auto-fill, minmax(200px, 350px));
    }

    &--1 {
      margin: 1.5rem 0;

    }
  }

  .next-section--2 {
    padding: 0.5rem 0;
    width: 70%;
    margin: 0 auto;

    .main {
      justify-content: center;
      grid-gap: 3rem;
      border: 2px solid black;
      padding: 3rem 0rem;


      button {
        background: #FFEAED 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 5px;
        font: normal normal normal 16px/18px Ubuntu;
        letter-spacing: 0px;
        color: #2A2A2A;
        width: 112px;
        height: 44px;
        margin-top: 20px;

      }

      .body-seperator {
        grid-gap: 2rem;


        .label--input {
          grid-gap: 1rem;
          flex-wrap: wrap;
        }

        .restrict--type {
          width: 20%;
          max-width: 200px;

          select {
            cursor: pointer;
          }
        }
      }

      .button-add-cont {
        align-items: flex-start;
        justify-content: flex-start;

        p {
          color:#D0021B; 
          padding-top: 1rem;
        }

        .button--add {
          border: 2px solid #D0021B;
          align-self: flex-start;
          height: 60px;
          width: 60px;
          border-radius: 50%;
          background-color: white;
          color:#D0021B; 
          font-size: 2rem;
        }

      }
    }

    #button--cont {
      width: 100%;
      justify-content: flex-end;
      padding-top: 3rem;
    }
  }
}

.section-table {
  
  margin: 0rem 2rem;
  
}

.error-container-z .error--both {
  margin: 32px 0px 0;
}

.error-container-z .error--icon {
  color: red;
  font-size: 14px;
  margin-top: 43px;
}

.error-container-z .icon--error {
  transform: translateY(-2rem);
  position: relative;
  z-index: -1;
}

.select--customize {
  height: 44px !important;
  z-index: 120;
}

.product--flex {
  grid-gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 7px;
  
  .input--1 {
    width: 120px;
  }
  .input--2 {
    width: 350px;
  }
  
  input {
    min-width: 10px;
  }
}

.button--cont {
  margin-top: 2rem;
}

.control-editview{
  width: 15%;
  padding: 0.5rem;
  border-radius: 5px;
  text-align: center;

}

.control-editview:hover{
  cursor: pointer;
  color: #850516;
}

.save{
  padding-top: 2rem;
  margin: 0 auto;
}

}
