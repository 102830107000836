.reset--password{
    .heading {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
        padding:15px;
        border-radius: 5px;
    }
    .heading p{
        color: white;
        font-size: 18px;
        font-weight: bold;
    }

    %label-input {
        label {
            margin-bottom: 3px;
            letter-spacing: 0px;
            color: #262C2C;
        }

        input, select {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #707070;
            border-radius: 5px;
            width: 30%;
            // max-width: 400px;
            // min-width: 250px;
            height: 46px;
            color: rgb(65, 64, 64);
            padding-left: 1rem;
            margin-bottom: 1rem;

        }
    }

    .section--1{
        padding: 3rem;
        // padding-bottom: 5rem; 
        // padding-left: 3rem;
        // padding-right: 3rem;
        border-bottom: 1px solid #dcdacb;
        box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);
        .ErrorContent{
            justify-content: center;
            align-items: center;
        }
    }

    // .reset--password{
    //     width: 10%;
    // }

    .reset--input{
        .reset--head{

        }
        .reset--p{
            margin-bottom: 1rem; 
        }
        .reset--input-1{
            @extend %label-input;
        }

        button{
            background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 6px 16px #B522684D;
                border-radius: 5px;
                font: normal normal bold 16px/18px Ubuntu;
                letter-spacing: 0px;
                color: #FFFFFF;
                width: 180px;
                height: 46px;
                margin-top: 2rem;
                margin-left: auto;
                margin-bottom: 25px;
        }
    }
}