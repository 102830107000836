.next--Kin{
    .new-grid{
        .button--new {
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            gap: 5rem;
            padding: 30px 0 0 0;
    
            .flex-3 {
                align-items: center;
                padding-top: 30px;
                .span{
                    position: relative;
                    top: 5px;
                    left: 6px;
                }
            }
    
            button {
                border-radius: 50%;
                padding: .3rem 0rem;
                border: 1px solid #D0021B;
                width: 10px;
                // height:15px;
                font: normal normal medium 24px/28px Ubuntu;
                font-size: 20px;
                letter-spacing: 0px;
                color: #262C2C;
                // border-bottom: 1px solid #dcdacb;
                box-shadow: 0 5px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);
                transition: all .2s ease-in;
                margin-bottom: .5rem;
    
                &:hover {
                    box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
                }
    
                &:active {
                }
    
                &:focus {
                }
            }
    
            .active-a {
                background-color: #FFEAED;
                color: #D0021B;
                box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
            }
            
            .active-b {
                background-color: #FFEAED;
                color: #D0021B;
                box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
            }
        }
        .product--def {
            grid-gap: 1rem;
            flex-wrap: wrap;
            margin-bottom: 7px; 
          
            .flex--3 {
              flex: 1 1 150px;
            }
            
            .input--1 {
              max-width: 150px;
            }
          
            .input--2 {
              min-width: 200px;
              max-width: 540px
            }
            .input--3{
              max-width: 300px;
            }
            .input--4{
              max-width: 420px;
            }
            .input--5{
              max-width: 135px;
            }
            input {
              min-width: 10px;
            }
            select{
                min-width: 10px;
            }
          } 
          .whole{
              width: 50%;
              margin-top: 1px;
              label{
                  padding-bottom: 1px;
              }
            
          }  
    
        .button-last {
            width: 90%;
            margin: 0 auto;
            display: flex;
            position: relative;
            left:55%;
    
            button {
                background: transparent linear-gradient(117deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 6px 16px #B522684D;
                border-radius: 5px;
                font: normal normal bold 16px/18px Ubuntu;
                letter-spacing: 0px;
                color: #FFFFFF;
                width: 180px;
                height: 46px;
                margin-top: 2rem;
                margin-left: auto;
    
            }
        }
        
        h5{
            padding-left:50px;
            padding-top: 30px;
        }
        select {
            // min-width: 100%;
            height: 44px;
            margin-left: 10px;
            border-radius: 5px;
            margin: 6px 0;
            min-width: 500px;
          }
          input {
            // min-width: 100%;
            height: 44px;
            margin-left: 10px;
            border-radius: 5px;
            margin: 6px 0;
            min-width: 500px;
          }  
          label{
            margin-bottom: -0.5rem;  
          }
    
        .checboxdiv {
            display: flex;
            align-items: center;
            position:relative;
            top:42px;
            left:10px;
        
            .productcheckbox{
            min-width: 0;
            height: 15px;
            border-radius: 0;
            margin: 0;
            position: relative;
            top: 10px;
            
            }
            .span{
                position: relative;
                top: 5px;
               
            }    
        }
    }

    select {
        padding-left: 10px;
    }

    .icon--error {
        transform: translateY(-10px);
    }
    
    .error-single {
        transform: translateY(-5px);
    }

    
}
