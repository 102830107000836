.user--account{
    
    
    .headerdiv {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
        padding:15px;
        border-radius: 5px;
        color: white
    }

    p{
        font-size: 18px;
    }
    label{
        margin-left: 0px;
        margin-bottom: 0;
    }
    select{
        min-width: 100%;
        height:44px;
        margin-left: 10px;
        border-radius: 5px;
        margin:4px 0;
        
    }
    input{
     min-width: 100%;
     height:44px;
     margin-left: 10px;
     border-radius: 5px; 
     margin:4px 0; 
    }
    .roledetails, .userstatus{
    background-color: rgb(245, 227, 230);
    width:100%;
    padding:15px;
    border-radius: 5px;
    }
    .checkboxdiv{
        display:flex;
        justify-content: space-between;
        white-space: nowrap;
        margin-top: 20px;

        .checkboxdiv1{
            display:flex;
            justify-content: space-between; 
        }
        .inputcheck{
            width:20px;
            height:15px;
            position: relative;
            right: 45px;
            
        }
        .checkLabel{
            border:1px solid red;
            // width:100px
        }
    }
    table {
        width: 100%;
        font: 14px;
        white-space: nowrap;
       
       .line{
           height: 1px;
           width:300%;
           background-color: grey;
       }
    }

    th {
        text-align: left;
        padding: 8px;
        border: none;
    }
    td {
        text-align: left;
        padding: 8px;
        border: none;
        
        // button{
        //     width:30px;
        //     border-radius: 5px;
        //     box-shadow:2px 2px;
        // }
        
        .table--icon {
           margin-left:14px;
           cursor: pointer;
        }
    }
    .save{
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;  
        color:white;
        position:relative;
        width:100px;
        height:44px;
        border-radius: 7px;
        margin-top: 30px;
        margin-bottom: 20px;
        left:45%
    
    }
.main-filter {
    margin: 1rem 0 2rem auto;

    .filter  {
        border: 1px solid #B52268;
        border-radius: 5px;
        width: 120px;
        min-width: 150px;
        height: 44px;
        padding:  0 0 0 1rem;
    }

    .button--second {
        background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        border-radius: 5px;
        font: normal normal bold 16px/18px Ubuntu;
        letter-spacing: 0px;
        color: #FFFFFF;
        width: 160px;
        height: 44px;
        margin: 0 2rem;

        &:nth-child(3) {
            background: transparent;
            font: normal normal bold 16px/18px Ubuntu;
            color: #D0021B;
            border: 2px solid #D0021B;
            width: 200px;
            margin-right: 0;
        }

    }
}
// .update--box{
    .input-container--1{
        grid-gap: 1rem;
        padding-bottom: 1rem;
        flex-wrap: wrap;

        .flex--3 {
            flex: 1 1;
            min-width: 150px;
            max-width: 600px;
        }
        .emailinput{
            flex: 1 1;
            min-width: 150px;
            max-width: 570px; 
        }
         }

.button{
    margin-top: 20px;

    .updatebutton{
        background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        border-radius: 5px;
        font: normal normal bold 16px/18px Ubuntu;
        letter-spacing: 0px;
        color: #FFFFFF;
        width: 160px;
        height: 44px;
        margin: 0 2rem;
    }
}
}
