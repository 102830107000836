.branchteller--tills{
    .headerdiv {
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
        padding:15px;
        border-radius: 5px;
        color: white;
    
    p{
        font-size: 18px;
        font-weight: bold;
    }
}

label{
    margin-left: 0px;
    margin-bottom: 0;
}
input,select{
 min-width: 100%;
 height:44px;
 margin-left: 10px;
 border-radius: 5px; 
 margin:4px 0; 
}
.ccyiaccount{
    background-color: rgb(245, 227, 230);
    width:100%;
    padding:15px;
    border-radius: 5px;
    display: flex;
    .currency{
        width:20px;
        border-color: #D0021B;
        text-align: center;
        @media screen and (min-width:400px) {
            width:50px;
            // margin-top: 35px;
            }
          
    }
    .acct{
        margin-left: 40px;
        border-color: #D0021B;
        @media screen and (min-width:400px) {
        width:124px;
        }
    }
  
}
.save{
    background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;  
    color:white;
    position:relative;
    width:100px;
    height:44px;
    border-radius: 7px;
    margin-top: 40px;
    margin-bottom: 20px;
    left:85%
}
.sub--section {
    position: relative;
    right: 0;


.main-filter {
    margin: 1rem 0 2rem auto;

    .filter  {
        border: 1px solid #B52268;
        border-radius: 5px;
        width: 30px;
        min-width: 150px;
        height: 44px;
        padding:  0 0 0 1rem;
    }

    button {
        background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
        border-radius: 5px;
        font: normal normal bold 16px/18px Ubuntu;
        letter-spacing: 0px;
        color: #FFFFFF;
        width: 160px;
        height: 44px;
        margin: 0 2rem;

        &:nth-child(3) {
            background: transparent;
            font: normal normal bold 16px/18px Ubuntu;
            color: #D0021B;
            border: 2px solid #D0021B;
            width: 200px;
            margin-right: 0;
        }

    }
}
 }
}