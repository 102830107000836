.lien--amount{
    .headerdiv{
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
        padding:15px;
        border-radius: 5px;
        color: white;
    
    p{
       font-size: 18px;
       font-weight: bold;
    }
    }
    label {
        margin-left: 0px;
        margin: 0;
      }
      input { 
        height: 44px;
        margin-left: 10px;
        border-radius: 5px;
        margin: 1px 0;
        position: relative;
      }
      select{ 
        height:44px;
        margin-left: 10px;
        border-radius: 5px;
        margin:1px 0;
        padding-left: 1rem;
        position: relative;
    }
    .product--def {
        grid-gap: 1rem;
        flex-wrap: wrap;
        margin-bottom: 7px;
      
        .flex--3 {
          flex: 1 1 150px;
        }

        .endDateHide{
          visibility: hidden;
        }
        
        .input--1 {
          max-width: 150px;
        }
      
        .input--2 {
          min-width: 200px;
          max-width: 540px
        }
        .input--3{
          max-width: 300px;
        }
        .input--4{
          max-width: 420px;
        }
        .input--5{
          max-width: 135px;
        }
        input {
          min-width: 10px;
        }
      }

      .btn-save{
        justify-content: flex-end;
        width: 90%;
        margin-top: 30px;
       
        .save{
          background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
          border-radius: 5px;
          color: #FFFFFF;
          width: 160px;
          height: 44px;
        }
      } 
      .sub--section {
        position: relative;
        right: 0;
    
    
    .main-filter {
        margin: 1rem 0 2rem auto;
    
        .filter  {
            border: 1px solid #B52268;
            border-radius: 5px;
            width: 30px;
            min-width: 150px;
            height: 44px;
            padding:  0 0 0 1rem;
        }
    
        button {
            background: transparent linear-gradient(128deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;
            border-radius: 5px;
            font: normal normal bold 16px/18px Ubuntu;
            letter-spacing: 0px;
            color: #FFFFFF;
            width: 160px;
            height: 44px;
            margin: 0 2rem;
    
            &:nth-child(3) {
                background: transparent;
                font: normal normal bold 16px/18px Ubuntu;
                color: #D0021B;
                border: 2px solid #D0021B;
                width: 200px;
                margin-right: 0;
            }
    
        }
    } 
}
}