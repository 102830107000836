.mc--branch {
    
    
    .headerdiv{
        background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box; 
        padding:15px;
        border-radius: 5px;
        color: white;
    
   p{
       font-size: 18px;
       font-weight: bold;
   } 
}
   label {
    margin-left: 0px;
    margin-bottom: 0px;
  }
  input {
    width: 100%;
    height: 44px;
    margin-left: 10px;
    border-radius: 5px;
    margin: 4px 0;
  }
  .btn{
    background: transparent linear-gradient(137deg, #D0021B 0%, #68010E 100%) 0% 0% no-repeat padding-box;  
    color:white;
    position:relative;
    width:230px;
    height:44px;
    border-radius: 7px;
    margin-top: 40px;
    left:60%;
    margin-bottom: 30px;
}

table {
    width: 100%;
    font: 14px;
    white-space: nowrap;
}

td, th {
    text-align: left;
    padding: 8px;
    border: none;
}
}
// .date--branch{
//     margin-top: 30px;
// }
// button, button:focus {
//     outline: none;
//     border: none;
//     cursor: pointer;
//   }