.main--body {
    margin: 0 0 3rem 25vw;
    padding-top: 5.5rem;
    width: calc(100% - 26vw);
    transition: all .2s ease-in;
}

.slide-body {
    margin: 0;
    width: 100%;
    padding: 5.5rem 1rem
}

@media screen and (min-width: 900px) {
    .main--body {
        margin: 0 0 3rem 25vw;
        width: calc(100% - 26vw);
        padding: 5.5rem 0;
    }
}