.debit-teller {
  .section--1 {
    padding-bottom: 5rem;
    border-bottom: 1px solid #dcdacb;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);

    .heading {
      background: transparent linear-gradient(137deg, #d0021b 0%, #68010e 100%)
        0% 0% no-repeat padding-box;
      padding: 1rem 3rem;
      font: normal normal bold 22px/28px Ubuntu;
      letter-spacing: 0px;
      color: #ffffff;
      margin-bottom: 3rem;
    }
   .backButton{
    background: #ffffff 0% 0% no-repeat padding-box;
    text-align: left;
    align-content: center;
    display: flex;
    align-items: center;
    justify-content: left;

   }
   .cards{
     width:200px;
     height:200px;
     align-items: center;
     padding:2rem;
   }
    .button--double {
      width: 90%;
      margin: 20px auto 0;
      justify-content: center;
      gap: 8rem;
      padding: 0 0 2rem 0;

      .flex--3 {
        align-items: center;
      }

      button {
        border-radius: 50%;
        padding: 0.5rem 1rem;
        border: 3px solid #d0021b;
        width: 50px;
        font: normal normal medium 24px/28px Ubuntu;
        font-size: 20px;
        letter-spacing: 0px;
        color: #262c2c;
        // border-bottom: 1px solid #dcdacb;
        box-shadow: 0 5px 4px rgba(0, 0, 0, 0.08),
          0 4px 12px rgba(0, 0, 0, 0.08);
        transition: all 0.2s ease-in;
        margin-bottom: 0.5rem;

        &:hover {
          box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
        }

        &:active {
        }

        &:focus {
        }
      }

      .active-1 {
        background-color: #ffeaed;
        color: #d0021b;
        box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
      }

      .active-2 {
        background-color: #ffeaed;
        color: #d0021b;
        box-shadow: 0px 0px 2px 5px rgb(153, 153, 153);
      }
    }
    .teller--def {
      grid-gap: 1rem;
      flex-wrap: wrap;
      margin-bottom: 7px;

      .flex--3 {
        flex: 1 1 150px;
      }

      // .input--1 {
      //   max-width: 150px;
      // }

      // .input--2 {
      //   min-width: 200px;
      //   max-width: 500px
      // }
      .input--3 {
        max-width: 350px;
      }
      // .input--4{
      //   max-width: 700px;
      // }
      // .input--5{
      //   max-width: 135px;
      // }
      input {
        min-width: 10px;
      }
      label {
        letter-spacing: 0px;
        color: #262c2c;
        margin-bottom: 4px;
      }

      input {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 5px;
        height: 44px;
        color: rgb(65, 64, 64);
        padding-left: 1rem;
        // margin: 4px 0;
      }
    }
    .input--select {
      flex: 1 1 210px;
      max-width: 350px;

      select {
        height: 44px;
        border-radius: 5px;
        width: 100%;
      }
    }
    .flex--5 {
      button {
        background: transparent
          linear-gradient(117deg, #d0021b 0%, #68010e 100%) 0% 0% no-repeat
          padding-box;
        box-shadow: 0px 6px 16px #b522684d;
        border-radius: 5px;
        color: #ffffff;
        width: 180px;
        height: 44px;
        margin-top: 2rem;
        margin-bottom: 25px;
      }
    }
    .button-last--1 {
      width: 90%;
      margin: 0 auto;
  
      .button-container {
        // margin-left: auto;
        gap: 3rem;
        margin-top: 2rem;
  
        button:first-child {
          background: transparent;
          border: 2px solid #d0021b;
          color: #d0021b;
        }
      }
    }
  }
}
